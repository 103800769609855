import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import cn from "classnames";
import "../../custom-modal.css";
import classes from "../../styles/modal.module.scss";
import alertClasses from "../../styles/alert.module.scss";
import { CloseIcon, LoaderIcon, AddDeviceIcon } from "../../assets/icons/";
import { ALL_LOCATIONS } from "../../constants";
import { setShopIdHeader } from "../../services/api.service";
import {
  fetchSteerCustomers,
  setCustomerError,
} from "../customers/customers.slice";
import {
  getIsCustomersLoading,
  isSteerCustomersLoading,
  getSteerCustomers,
} from "../customers/customers.selectors";
import {
  createDevice,
  clearError,
  fetchCustomerSteerVehicles,
  hideDeviceModal,
} from "../customer-details/vehicles.slice";
import {
  getVehicleState,
  getSteerCustomerVehicles,
  isSteerCustomerVehiclesLoading,
  isVehicleLoading,
} from "../customer-details/vehicles.selectors";
import { getSelectedTenant } from "../tenants/tenants.selectors";
import { setDeviceFilter, fetchShopDevices } from "../shops/shops.slice";
import {
  getShops,
  getSelectedShop,
  getFilteredDevices,
  isShopDevicesLoading,
} from "../shops/shops.selectors";
import { Modal, ModalBody, ModalFooter, FormGroup, Alert } from "reactstrap";
import SelectInput from "../../components/ui/select-input/SelectInput";

export default function DeviceModal() {
  const dispatch = useDispatch();
  const selectedTenant = useSelector(getSelectedTenant);
  const selectedShop = useSelector(getSelectedShop);
  const shops = useSelector(getShops);
  const vehicleState = useSelector(getVehicleState);
  const isCustomersLoading = useSelector(isSteerCustomersLoading);
  const steerCustomers = useSelector(getSteerCustomers);
  const steerCustomerVehicles = useSelector(getSteerCustomerVehicles);
  const isVehiclesLoading = useSelector(isSteerCustomerVehiclesLoading);
  const shopDevices = useSelector(getFilteredDevices);
  const isDevicesLoading = useSelector(isShopDevicesLoading);
  const isAddVehiclePending = useSelector(isVehicleLoading);
  const steerCustomerSearchQuery = useRef("");
  const steerVehicleSearchQuery = useRef("");
  const customerSelectRef = useRef(null);
  const vehicleSelectRef = useRef(null);
  const { modalData } = vehicleState;
  const deviceSelectRef = useRef(null);
  const [selectedCustomerSteerId, setSelectedCustomerSteerId] = useState(null);
  const [selectedShopId, setSelectedShopId] = useState(null);
  const [selectedSteerVehicleId, setSelectedSteerVehicleId] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  const isAllLocations = selectedShop.id === ALL_LOCATIONS.id;
  const isSubmitBtnDisabled =
    !selectedShopId ||
    !selectedCustomerSteerId ||
    !selectedSteerVehicleId ||
    !selectedDeviceId;

  useEffect(() => {
    if (vehicleState.isShownDeviceModal) {
      if (!isAllLocations) {
        setSelectedShopId(selectedShop.id);
      } else if (modalData?.shop) {
        setSelectedShopId(modalData.shop.id);
      }
    }
  }, [vehicleState.isShownDeviceModal, modalData?.shop]);

  useEffect(() => {
    deviceSelectRef.current && deviceSelectRef.current.select.clearValue();
    if (selectedShopId && !modalData?.customer) {
      customerSelectRef.current &&
        customerSelectRef.current.select.clearValue();
      setShopIdHeader(selectedShopId);
      dispatch(fetchSteerCustomers());
      dispatch(fetchShopDevices());
    }
  }, [selectedShopId, modalData?.customer]);

  useEffect(() => {
    vehicleSelectRef.current && vehicleSelectRef.current.select.clearValue();
    if (selectedCustomerSteerId) {
      dispatch(
        fetchCustomerSteerVehicles({ steerCustomerId: selectedCustomerSteerId })
      );
    }
  }, [selectedCustomerSteerId]);

  useEffect(() => {
    if (modalData?.customer) {
      setSelectedCustomerSteerId(modalData.customer.id);
    }
  }, [modalData?.customer]);

  const { handleSubmit, control } = useForm({});

  const onSubmit = async (data) => {
    if (isAddVehiclePending) return;

    dispatch(clearError());

    const {
      customer: { id: steerCustomerId },
      vehicle: { id: steerVehicleId },
      deviceId: { deviceSerialNumber },
    } = data;

    if (!steerCustomerId || !steerVehicleId || !deviceSerialNumber) return;
    dispatch(
      createDevice({
        tenantId: selectedTenant.id,
        steerCustomerId,
        steerVehicleId,
        deviceId: deviceSerialNumber,
      })
    );
  };

  const onToggle = () => {
    dispatch(hideDeviceModal());
    dispatch(setCustomerError(""));
    dispatch(clearError());
    dispatch(setDeviceFilter(""));
    setSelectedShopId(null);
    setSelectedCustomerSteerId(null);
  };

  const debouncedChangeCustomerInputSearch = useCallback(
    debounce((searchQuery) => {
      if (steerCustomerSearchQuery.current === searchQuery) return;
      steerCustomerSearchQuery.current = searchQuery;
      dispatch(fetchSteerCustomers(searchQuery));
    }, 800),
    []
  );

  const debounceChangeVehicleSearch = useCallback(
    debounce((searchQuery) => {
      if (
        steerVehicleSearchQuery.current === searchQuery ||
        !selectedCustomerSteerId
      )
        return;
      steerVehicleSearchQuery.current = searchQuery;
      dispatch(
        fetchCustomerSteerVehicles({
          steerCustomerId: selectedCustomerSteerId,
          searchQuery,
        })
      );
    }, 800),
    [selectedCustomerSteerId]
  );

  const debounceChangeDeviceIdSearch = useCallback(
    debounce((searchQuery) => {
      dispatch(setDeviceFilter(searchQuery));
    }, 800),
    []
  );

  return (
    <div>
      <Modal
        isOpen={vehicleState.isShownDeviceModal}
        toggle={onToggle}
        contentClassName={classes.Modal__Content}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div>
            <AddDeviceIcon className={classes.Modal__CarIcon} />

            <div className={classes.Modal__Title}>Install Device</div>
          </div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <hr className={classes.Modal_HorizontalLine} />
        <ModalBody className={classes.Modal__Body}>
          {vehicleState.error && (
            <Alert color="danger" className={alertClasses.DangerAlert}>
              {vehicleState.error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Controller
                name="shop"
                control={control}
                rules={{ required: true }}
                defaultValue={[]}
                render={({ onChange, value, name, error }) => (
                  <SelectInput
                    label="Shop Location"
                    options={shops}
                    onInputChange={(shop) => {
                      onChange(shop);
                      setSelectedShopId(shop.id);
                    }}
                    value={
                      isAllLocations
                        ? modalData?.shop
                          ? modalData.shop
                          : value
                        : selectedShop
                    }
                    disabled={
                      !isAllLocations || (isAllLocations && modalData?.shop)
                    }
                    name={name}
                    error={error}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    className="mb-24"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="customer"
                control={control}
                rules={{ required: true }}
                defaultValue={[]}
                render={({ onChange, value, name, error }) => (
                  <SelectInput
                    inputRef={customerSelectRef}
                    label="Customer"
                    options={
                      !selectedShopId || isCustomersLoading
                        ? []
                        : steerCustomers
                    }
                    onInputChange={(customer) => {
                      onChange(customer);
                      setSelectedCustomerSteerId(customer?.id);
                    }}
                    value={modalData?.customer ? modalData.customer : value}
                    disabled={
                      (!isAllLocations && modalData?.customer) ||
                      !selectedShopId
                    }
                    name={name}
                    error={error}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    className="mb-24"
                    isSearchable
                    onSearch={debouncedChangeCustomerInputSearch}
                    isLoading={isCustomersLoading}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                control={control}
                name="vehicle"
                rules={{ required: true }}
                defaultValue={[]}
                render={({ onChange, value, name, error }) => (
                  <SelectInput
                    inputRef={vehicleSelectRef}
                    label="Vehicle"
                    options={
                      !selectedShopId ||
                      isVehiclesLoading ||
                      !selectedCustomerSteerId
                        ? []
                        : steerCustomerVehicles
                    }
                    onInputChange={(vehicle) => {
                      onChange(vehicle);
                      setSelectedSteerVehicleId(vehicle?.id);
                    }}
                    disabled={!selectedShopId || !selectedCustomerSteerId}
                    value={value}
                    name={name}
                    error={error}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    className="mb-24"
                    isSearchable
                    onSearch={debounceChangeVehicleSearch}
                    isLoading={isVehiclesLoading}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                control={control}
                name="deviceId"
                rules={{ required: true }}
                defaultValue={[]}
                render={({ onChange, value, name, error }) => (
                  <SelectInput
                    inputRef={deviceSelectRef}
                    label="Device ID"
                    options={
                      !selectedShopId || isDevicesLoading ? [] : shopDevices
                    }
                    onInputChange={(deviceId) => {
                      onChange(deviceId);
                      dispatch(setDeviceFilter(""));
                      setSelectedDeviceId(deviceId);
                    }}
                    value={value}
                    disabled={!selectedShopId}
                    name={name}
                    error={error}
                    getOptionValue={(option) => option.deviceSerialNumber}
                    getOptionLabel={(option) => option.deviceSerialNumber}
                    className="mt-27"
                    onSearch={debounceChangeDeviceIdSearch}
                    isLoading={isDevicesLoading}
                  />
                )}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter className={classes.Modal__Footer}>
          <button className={classes.Modal__CancelBtn} onClick={onToggle}>
            Cancel
          </button>
          <button
            className={cn(classes.Modal__SubmitBtn, {
              [classes.Modal__SubmitBtn__Disabled]: isSubmitBtnDisabled,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            {isAddVehiclePending ? (
              <LoaderIcon className={classes.Modal__Loader} />
            ) : (
              "Install Device"
            )}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
