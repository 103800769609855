import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import styles from "./Dashboard.module.scss";

import {
  convertMetersToMiles,
  replaceSpaceToUnderscore,
  joinShopAddress,
} from "../../utils";
import { ALL_LOCATIONS } from "../../constants";

import { getRoutePrefix } from "../../services/env.service";
import { getSelectedTenant } from "../tenants/tenants.selectors";
import { getCustomers } from "../customers/customers.selectors";
import { setVehiclesFilters } from "../../App/ui.slice";
import { getShopsById, getSelectedShopId } from "../shops/shops.selectors";

import {
  VehicleIcon,
  VehicleWarningIcon,
  CheckEngineIcon,
  WarningIcon,
  DeviceIcon,
  ArrowBlueIcon,
  ServiceIcon,
  SortArrowDownIcon,
  EmptyServicesHistoryIcon,
  DashboardVehicleIcon,
  EmptyReadinessMonitorsIcon,
} from "../../assets/icons";
import EngineYellowIcon from "../../assets/icons/engine-yellow.svg";
import EngineGreenIcon from "../../assets/icons/engine-green.svg";
import DefaultCarAvatar from "../../assets/icons/default-car.png";

import EngineIcon from "../../components/engine-icon/EngineIcon";
import Spinner from "../../components/spinner/Spinner";
import InfoTooltip from "../../components/tooltip/InfoTooltip";
import VehicleWarnings from "../../components/tooltip/VehicleWarnings";
import VehiclePSI from "../../components/tooltip/VehiclePSI";
import VehicleDTC from "../../components/tooltip/VehicleDTC";
import { setShopIdHeader } from "../../services/api.service";

export default function Dashboard() {
  const selectedTenant = useSelector(getSelectedTenant);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Dashboard</div>
      <div className={styles.tenantName}>{`${
        selectedTenant.name || ""
      } Telematics program`}</div>
      <div className={styles.section}>
        <Summary />
      </div>
      <div className={styles.section}>
        <Vehicles />
      </div>
      <div className={styles.section}>
        <LastServices />
      </div>
    </div>
  );
}

function Summary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const customers = useSelector(getCustomers);
  const counters = useMemo(() => {
    return customers.reduce(
      (acc, customer) => {
        acc.installed = acc.installed + customer.vehicles.length;
        acc.checks = acc.checks + customer.vehiclesWithCheckEngineLight.length;
        acc.warnings = acc.warnings + customer.warningsCount;
        acc.other = acc.other + customer.otherAlertsCount;

        return acc;
      },
      { installed: 0, checks: 0, warnings: 0, other: 0 }
    );
  }, [customers]);

  return (
    <div className={styles.summary}>
      <div className={styles.card}>
        <VehicleIcon />
        <div className={styles.info}>
          <div className={styles.title}>Installed Devices</div>
          <div className={styles.number}>{counters.installed}</div>
          <div
            className={styles.link}
            onClick={() => {
              dispatch(setVehiclesFilters({}));
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            View Vehicles
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.card}>
        <CheckEngineIcon />
        <div className={styles.info}>
          <div className={styles.title}>Check Engine Lights</div>
          <div className={styles.number}>{counters.checks}</div>
          <div
            className={styles.link}
            onClick={() => {
              dispatch(
                setVehiclesFilters({
                  checkEngine: { isActive: true, data: true },
                })
              );
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            View Vehicles
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </div>
      </div>
      <div className={cn(styles.divider, styles.additional)} />
      <div className={styles.divider} />
      <div className={cn(styles.divider, styles.additional)} />
      <div className={styles.card}>
        <WarningIcon />
        <div className={styles.info}>
          <div className={styles.title}>Warning Lights</div>
          <div className={styles.number}>{counters.warnings}</div>
          <div
            className={styles.link}
            onClick={() => {
              dispatch(
                setVehiclesFilters({
                  warnings: { isActive: true, data: true },
                })
              );
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            View Vehicles
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.card}>
        <DeviceIcon />
        <div className={styles.info}>
          <div className={styles.title}>Other Alerts</div>
          <div className={styles.number}>{counters.other}</div>
          <div
            className={styles.link}
            onClick={() => {
              dispatch(
                setVehiclesFilters({
                  others: { isActive: true, data: true },
                })
              );
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            View Vehicles
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
}

const VEHICLES_COLUMNS = {
  vehicle: "Vehicle",
  customer: "Customer",
  vehicleCustomer: "Vehicle / Customer",
  check: "Check Engine Light",
  dtcs: "DTC Codes",
  warnings: "Warning Lights",
  others: "Other Alerts",
  psi: "Lowest PSI",
  shop: "Shop Location",
};
const VEHICLES_SORT_ORDER = [
  "check",
  "warnings",
  "dtcs",
  "others",
  "psi",
  "customer",
  "vehicle",
];

function Vehicles() {
  const history = useHistory();
  const dispatch = useDispatch();
  const customers = useSelector(getCustomers);
  const shops = useSelector(getShopsById);
  const [warningVehicles, setWarningVehicles] = useState(null);
  const [sortedVehicles, setSortedVehicles] = useState(null);
  const [sortingConfig, setSortingConfig] = useState({
    fields: VEHICLES_SORT_ORDER,
    direction: "desc",
  });
  const selectedShopId = useSelector(getSelectedShopId);
  const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

  useEffect(() => {
    const warningVehicles = customers.reduce((acc, customer) => {
      const shop = shops[customer.shopId];
      const shopName = shop?.name;
      const shopAddress = joinShopAddress(shop, false);

      const customerVehicles = Object.entries(customer.vehiclesData).reduce(
        (acc, [vehicleId, vehicleData]) => {
          if (
            vehicleData.checkEngine ||
            vehicleData.dtcCount ||
            vehicleData.warningsCount ||
            vehicleData.otherAlertsCount ||
            vehicleData.lowestPSI
          ) {
            acc.push({
              customerId: customer.id,
              shopId: customer.shopId,
              customer: customer.name,
              vehicleId,
              deviceId: vehicleData.deviceId,
              vehicle: vehicleData.name,
              vehicleCustomer: customer.name + vehicleData.name,
              check: vehicleData.checkEngine,
              vinData: vehicleData.vinData,
              dtcs: vehicleData.dtcCount,
              warnings: vehicleData.warnings,
              warningsData: vehicleData.warningsData,
              others: vehicleData.otherAlerts,
              othersData: vehicleData.otherAlertsData,
              psi: vehicleData.lowestPSI,
              tirePressures: vehicleData.tirePressures,
              shopName,
              shopAddress,
            });
          }

          return acc;
        },
        []
      );
      return [...acc, ...customerVehicles];
    }, []);
    setWarningVehicles(warningVehicles);
  }, [customers]);

  useEffect(() => {
    if (!warningVehicles) return;

    const sorted = [...warningVehicles].sort((a, b) => {
      const { fields, direction } = sortingConfig;
      for (const field of fields) {
        if (a[field] === b[field]) continue;
        if (a[field] === "N/A" || a[field] === undefined) {
          return direction === "asc" ? -1 : 1;
        }
        if (b[field] === "N/A" || b[field] === undefined) {
          return direction === "asc" ? 1 : -1;
        }
        return direction === "asc"
          ? (a[field] > b[field] && 1) || -1
          : (b[field] > a[field] && 1) || -1;
      }
      return 0;
    });

    setSortedVehicles(sorted);
  }, [sortingConfig, warningVehicles]);

  const handleChangeSortingConfig = ({ field }) => {
    const { fields, direction } = sortingConfig;
    const invertedDirection = direction === "desc" ? "asc" : "desc";
    const newDirection = fields[0] !== field ? "desc" : invertedDirection;

    const newFields = VEHICLES_SORT_ORDER.reduce(
      (acc, el) => {
        if (el !== field) acc.push(el);
        return acc;
      },
      [field]
    );
    setSortingConfig({
      fields: newFields,
      direction: newDirection,
    });
  };

  if (!sortedVehicles) {
    return (
      <div className={styles.vehicles}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.vehicles}>
      <div className={styles.header}>
        <VehicleWarningIcon className={styles.icon} />
        <div className={styles.title}>
          <div className={styles.name}>Vehicles That Need Your Attention</div>
          <div className={styles.description}>
            All vehicles that have a check engine light, warning light, DTC
            code, or other alerts
          </div>
        </div>
        <div className={styles.counter}>
          <DashboardVehicleIcon />
          <div className={styles.text}>Vehicles:</div>
          <div className={styles.count}>{warningVehicles.length}</div>
        </div>
      </div>
      {sortedVehicles.length ? (
        <>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              {Object.entries(VEHICLES_COLUMNS).map(([field, name]) => {
                if (field === "shop" && !isAllLocations) return null;
                return (
                  <div
                    key={field}
                    className={cn(styles.cell, styles[field])}
                    onClick={() => {
                      if (field !== "shop")
                        handleChangeSortingConfig({ field });
                    }}
                  >
                    <div data-max-width-fit-content>{name}</div>
                    {field === "psi" ? (
                      <InfoTooltip
                        text="Lowest PSI value that is being reported"
                        className={styles.tooltip_psi}
                      />
                    ) : null}
                    {field !== "shop" ? (
                      <SortArrowDownIcon
                        className={cn([
                          styles.icon,
                          {
                            [styles.visible]: sortingConfig.fields[0] === field,
                            [styles.asc]:
                              sortingConfig.fields[0] === field &&
                              sortingConfig.direction === "asc",
                          },
                        ])}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
            {sortedVehicles.map((vehicle) => (
              <VehicleRow
                key={vehicle.vehicleId}
                {...vehicle}
                isAllLocations={isAllLocations}
              />
            ))}
          </div>
          <div
            className={styles.button}
            onClick={() => {
              dispatch(setVehiclesFilters({}));
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            Show More
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </>
      ) : (
        <div className={styles.noData}>
          <EmptyReadinessMonitorsIcon className={styles.icon} />
          <div className={styles.text}>There is no data to display.</div>
        </div>
      )}
    </div>
  );
}

function VehicleRow({
  vehicleId,
  vehicle,
  deviceId,
  check,
  vinData,
  customerId,
  customer,
  shopId,
  dtcs,
  warnings,
  warningsData,
  others,
  othersData,
  psi,
  tirePressures,
  shopName,
  shopAddress,
  isAllLocations,
}) {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleArrowClick = () => setIsExpanded((prev) => !prev);
  const selectedShopId = getRoutePrefix();
  const handleVehicleClick = () => {
    if (selectedShopId === ALL_LOCATIONS.id) {
      setShopIdHeader(shopId);
      history.push(`/tenant/customer/${customerId}/vehicle/${vehicleId}`);
    } else {
      history.push(`/${shopId}/customer/${customerId}/vehicle/${vehicleId}`);
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div className={cn(styles.row, { [styles.expanded]: isExpanded })}>
      <VehicleCell
        {...{ vehicle, customer, checkEngine: check, vinData }}
        onClick={handleVehicleClick}
      />
      <div className={cn(styles.cell, styles.customer)}>
        <div className={styles.name}>{customer}</div>
      </div>
      <div className={cn(styles.cell, styles.check)}>
        {check ? "On" : "Off"}
      </div>
      <div className={styles.cell}>
        {dtcs ? (
          <>
            {dtcs}{" "}
            <InfoTooltip>
              <VehicleDTC
                vehicleId={vehicleId}
                deviceId={deviceId}
                shopId={shopId}
              />
            </InfoTooltip>
          </>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.cell}>
        {warnings ? (
          <>
            {warnings}{" "}
            <InfoTooltip>
              <VehicleWarnings
                warnings={Object.entries(warningsData).map(([id, data]) => ({
                  id,
                  text: data.text,
                }))}
              />
            </InfoTooltip>
          </>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.cell}>
        {others ? (
          <>
            {others}{" "}
            <InfoTooltip>
              <VehicleWarnings
                warnings={Object.entries(othersData).map(([id, data]) => ({
                  id,
                  text: data.text,
                }))}
                title="Other Alerts"
              />
            </InfoTooltip>
          </>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.cell}>
        {psi ? (
          <>
            {psi}{" "}
            <InfoTooltip>
              <VehiclePSI tirePressures={tirePressures} />
            </InfoTooltip>
          </>
        ) : (
          "-"
        )}
      </div>
      {isAllLocations && (
        <div className={cn(styles.cell, styles.shop)}>
          <div className={styles.name}>{shopName}</div>
          <div className={styles.address}>{shopAddress}</div>
        </div>
      )}
      <div className={styles.arrow} onClick={handleArrowClick} />
      <div className={cn(styles.cellMobile, styles.customer)}>
        <div className={styles.parameter}>Customer</div>
        <div className={styles.value}>{customer}</div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Check Engine Light</div>
        <div className={styles.value}>{check ? "On" : "Off"}</div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>DTC Codes</div>
        <div className={styles.value}>
          {" "}
          {dtcs ? (
            <>
              {dtcs}{" "}
              <InfoTooltip>
                <VehicleDTC
                  vehicleId={vehicleId}
                  deviceId={deviceId}
                  shopId={shopId}
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Warning Lights</div>
        <div className={styles.value}>
          {warnings ? (
            <>
              {warnings}{" "}
              <InfoTooltip>
                <VehicleWarnings
                  warnings={Object.entries(warningsData).map(([id, data]) => ({
                    id,
                    text: data.text,
                  }))}
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Other Alerts</div>
        <div className={styles.value}>
          {others ? (
            <>
              {others}{" "}
              <InfoTooltip>
                <VehicleWarnings
                  warnings={Object.entries(othersData).map(([id, data]) => ({
                    id,
                    text: data.text,
                  }))}
                  title="Other Alerts"
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Lowest PSI</div>
        <div className={styles.value}>
          {psi ? (
            <>
              {psi}{" "}
              <InfoTooltip>
                <VehiclePSI tirePressures={tirePressures} />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      {isAllLocations && (
        <div className={styles.cellMobile}>
          <div className={styles.parameter}>Shop Location</div>
          <div className={styles.shop}>
            <div className={styles.name}>{shopName}</div>
            <div className={styles.address}>{shopAddress}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function LastServices() {
  const history = useHistory();
  const dispatch = useDispatch();
  const customers = useSelector(getCustomers);
  const shops = useSelector(getShopsById);
  const [services, setServices] = useState(null);
  const [sortedServices, setSortedServices] = useState(null);
  const [sortingConfig, setSortingConfig] = useState({
    field: "distance",
    direction: "desc",
  });
  const selectedShopId = useSelector(getSelectedShopId);
  const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

  useEffect(() => {
    const services = customers.reduce((acc, customer) => {
      const shop = shops[customer.shopId];
      const shopName = shop?.name;
      const shopAddress = joinShopAddress(shop, false);

      const customerServices = Object.entries(customer.vehiclesData).reduce(
        (acc, [vehicleId, vehicleData]) => {
          if (!vehicleData.lastService) return acc;

          const distance =
            vehicleData.lastService.odometerSince >= 0
              ? convertMetersToMiles(vehicleData.lastService.odometerSince)
              : "N/A";

          acc.push({
            customerId: customer.id,
            customer: customer.name,
            vehicleId,
            vehicle: vehicleData.name,
            vehicleCustomer: customer.name + vehicleData.name,
            checkEngine: vehicleData.checkEngine,
            vinData: vehicleData.vinData,
            service: vehicleData.lastService.name,
            distance,
            shopName,
            shopAddress,
          });
          return acc;
        },
        []
      );
      return [...acc, ...customerServices];
    }, []);
    setServices(services);
  }, [customers]);

  useEffect(() => {
    if (!services) return;

    const sorted = [...services].sort((a, b) => {
      const { field, direction } = sortingConfig;
      if (a[field] === "N/A") return 1;
      return direction === "asc"
        ? (a[field] > b[field] && 1) || -1
        : (b[field] > a[field] && 1) || -1;
    });

    setSortedServices(sorted);
  }, [sortingConfig, services]);

  const handleChangeSortingConfig = ({ field: newField }) => {
    const { field, direction } = sortingConfig;
    const invertedDirection = direction === "desc" ? "asc" : "desc";
    const newDirection = field !== newField ? "desc" : invertedDirection;

    setSortingConfig({
      field: newField,
      direction: newDirection,
    });
  };

  if (!sortedServices) {
    return (
      <div className={styles.services}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.services}>
      <div className={styles.header}>
        <ServiceIcon className={styles.icon} />
        <div className={styles.title}>
          <div className={styles.name}>Miles Since Last Service</div>
          <div className={styles.description}>
            Vehicles with the most miles since the last service
          </div>
        </div>
      </div>
      {sortedServices.length ? (
        <>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div
                className={cn(styles.cell, styles.vehicle)}
                onClick={() => handleChangeSortingConfig({ field: "vehicle" })}
              >
                Vehicle
                <SortArrowDownIcon
                  className={cn([
                    styles.icon,
                    {
                      [styles.visible]: sortingConfig.field === "vehicle",
                      [styles.asc]:
                        sortingConfig.field === "vehicle" &&
                        sortingConfig.direction === "asc",
                    },
                  ])}
                />
              </div>
              <div
                className={cn(styles.cell, styles.customer)}
                onClick={() => handleChangeSortingConfig({ field: "customer" })}
              >
                Customer
                <SortArrowDownIcon
                  className={cn([
                    styles.icon,
                    {
                      [styles.visible]: sortingConfig.field === "customer",
                      [styles.asc]:
                        sortingConfig.field === "customer" &&
                        sortingConfig.direction === "asc",
                    },
                  ])}
                />
              </div>
              <div
                className={cn(styles.cell, styles.vehicleCustomer)}
                onClick={() =>
                  handleChangeSortingConfig({ field: "vehicleCustomer" })
                }
              >
                Vehicle / Customer
                <SortArrowDownIcon
                  className={cn([
                    styles.icon,
                    {
                      [styles.visible]:
                        sortingConfig.field === "vehicleCustomer",
                      [styles.asc]:
                        sortingConfig.field === "vehicleCustomer" &&
                        sortingConfig.direction === "asc",
                    },
                  ])}
                />
              </div>
              <div
                className={styles.cell}
                onClick={() => handleChangeSortingConfig({ field: "service" })}
              >
                Service
                <SortArrowDownIcon
                  className={cn([
                    styles.icon,
                    {
                      [styles.visible]: sortingConfig.field === "service",
                      [styles.asc]:
                        sortingConfig.field === "service" &&
                        sortingConfig.direction === "asc",
                    },
                  ])}
                />
              </div>
              <div
                className={styles.cell}
                onClick={() => handleChangeSortingConfig({ field: "distance" })}
              >
                <div data-max-width-fit-content>Miles Since Last Service</div>
                <SortArrowDownIcon
                  className={cn([
                    styles.icon,
                    {
                      [styles.visible]: sortingConfig.field === "distance",
                      [styles.asc]:
                        sortingConfig.field === "distance" &&
                        sortingConfig.direction === "asc",
                    },
                  ])}
                />
              </div>
              {isAllLocations && (
                <div className={cn(styles.cell, styles.shop)}>
                  <div data-max-width-fit-content>Shop Location</div>
                </div>
              )}
            </div>
            {sortedServices.map((service) => (
              <ServiceRow
                key={service.vehicleId}
                {...service}
                isAllLocations={isAllLocations}
              />
            ))}
          </div>
          <div
            className={styles.button}
            onClick={() => {
              dispatch(setVehiclesFilters({}));
              const shopId = getRoutePrefix();
              history.push(`/${shopId}/vehicles`);
            }}
          >
            Show More
            <ArrowBlueIcon className={styles.icon} />
          </div>
        </>
      ) : (
        <div className={styles.noData}>
          <EmptyServicesHistoryIcon className={styles.icon} />
          <div className={styles.text}>There is no data to display.</div>
        </div>
      )}
    </div>
  );
}

function ServiceRow({
  vehicleId,
  vehicle,
  checkEngine,
  vinData,
  customerId,
  customer,
  service,
  distance: propDistance,
  shopName,
  shopAddress,
  isAllLocations,
}) {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleArrowClick = () => setIsExpanded((prev) => !prev);
  const handleVehicleClick = () => {
    const shopId = getRoutePrefix();
    history.push(`/${shopId}/customer/${customerId}/vehicle/${vehicleId}`);
    window.scrollTo({ top: 0, behavior: "instant" });
  };
  const distance = propDistance?.toLocaleString(["en-US"]);

  return (
    <div className={cn(styles.row, { [styles.expanded]: isExpanded })}>
      <VehicleCell
        {...{ vehicle, customer, checkEngine, vinData }}
        onClick={handleVehicleClick}
      />
      <div className={cn(styles.cell, styles.customer)}>{customer}</div>
      <div className={styles.cell}>{service}</div>
      <div className={styles.cell}>{distance}</div>
      {isAllLocations && (
        <div className={cn(styles.cell, styles.shop)}>
          <div className={styles.name}>{shopName}</div>
          <div className={styles.address}>{shopAddress}</div>
        </div>
      )}

      <div className={styles.arrow} onClick={handleArrowClick} />
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Customer</div>
        <div className={styles.value}>{customer}</div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Service</div>
        <div className={styles.value}>{service}</div>
      </div>
      <div className={styles.cellMobile}>
        <div className={styles.parameter}>Miles Since Last Service</div>
        <div className={styles.value}>{distance}</div>
      </div>
      {isAllLocations && (
        <div className={styles.cellMobile}>
          <div className={styles.parameter}>Shop Location</div>
          <div className={styles.shop}>
            <div className={styles.name}>{shopName}</div>
            <div className={styles.address}>{shopAddress}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function VehicleCell({ vehicle, customer, checkEngine, vinData, onClick }) {
  const [avatarURL, setAvatarURL] = useState(DefaultCarAvatar);
  const checkIcon = checkEngine ? EngineYellowIcon : EngineGreenIcon;

  useEffect(() => {
    (async () => {
      if (!vinData) return;
      const { year, make, model } = vinData;
      if (!year || !make || !model) return;

      const url = `https://images.mechanicadvisor.com/Resources/Upload/Images/Specifications/${year}_${replaceSpaceToUnderscore(
        make
      )}_${replaceSpaceToUnderscore(model)}.png`;

      try {
        const res = await fetch(url);
        if (res.ok) setAvatarURL(url);
      } catch {}
    })();
  }, []);

  return (
    <div className={cn(styles.cell, styles.vehicle)} onClick={onClick}>
      <div
        style={{ backgroundImage: `url(${avatarURL})` }}
        className={styles.icon}
      >
        <EngineIcon icon={checkIcon} className={styles.check} />
      </div>
      <div className={styles.name}>
        {vehicle}
        {customer ? <div className={styles.customer}>{customer}</div> : null}
      </div>
    </div>
  );
}
