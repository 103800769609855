import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

import {
  fetchCustomerVehicles,
  fetchDeviceOverview,
  fetchLatestMonitorData,
  fetchCoreVehicleData as loadCoreData,
  fetchFaults,
  clearDTC,
  createCustomerVehicle as createCustomerVehicleRequest,
  editCustomerVehicle as editCustomerVehicleRequest,
  deleteCustomerVehicle as deleteCustomerVehicleRequest,
  createCustomer as createCustomerRequest,
  fetchDiagnosticOptions as fetchDiagnosticOptionsRequest,
  fetchDiagnosticValues as fetchDiagnosticValuesRequest,
  fetchDiagnosticWarnings as fetchDiagnosticWarningsRequest,
  fetchVehicleAlerts,
  fetchAlertsPastActivityByVehicleId,
  deleteAlert as deleteAlertRequest,
  fetchVehicleRecommendedServices,
  fetchCustomerSteerVehicles as fetchCustomerSteerVehiclesRequest,
  fetchVehicleServicesHistory,
} from "../../services/api.service";

import {
  setCustomerVehicleListLoading,
  setCustomerVehicleListLoadingSuccess,
  setCustomerVehicleListLoadingError,
  fetchCustomers,
} from "../customers/customers.slice";
import { getVehiclesById, getSelectedVehicle } from "./vehicles.selectors";
import {
  getMeasurementSystem,
  getSelectedCustomer,
} from "../customers/customers.selectors";
import {
  convertMetersToMiles,
  convertMetersToKilometers,
  convertLitersToGallons,
  convertPascalsToPsi,
  convertPascalsToBar,
  convertCelsiusToFahrenheit,
  convertlitersPer100KmToMpg,
  getFromSessionStorage,
  setToSessionStorage,
} from "../../utils";

const fetchCustomerVehiclesThunk = createAsyncThunk(
  "customerVehicles/fetch",
  async (customerId, thunkAPI) => {
    //TODO MP: can this be rewritten to use action.meta.arg instead of explicitly providing customerId?
    thunkAPI.dispatch(setCustomerVehicleListLoading(customerId));
    try {
      const customerVehicles = await fetchCustomerVehicles(customerId);
      thunkAPI.dispatch(setCustomerVehicleListLoadingSuccess(customerId));
      return customerVehicles;
    } catch (e) {
      thunkAPI.dispatch(setCustomerVehicleListLoadingError(customerId));
      throw e;
    }
  }
);

const fetchVehicleOverviewThunk = createAsyncThunk(
  "vehicle/overview",
  async (vehicleId, thunkAPI) => {
    const { deviceId } = getVehiclesById(thunkAPI.getState())[vehicleId];
    const measurementSystem = getMeasurementSystem(thunkAPI.getState());
    const payload = await fetchDeviceOverview(deviceId);
    payload.measurementSystem = measurementSystem;
    return payload;
  }
);

const fetchCoreVehicleDataThunk = createAsyncThunk(
  "vehicle/coreData",
  async (vehicle, thunkAPI) => {
    const measurementSystem = getMeasurementSystem(thunkAPI.getState());
    const payload = await loadCoreData({ deviceId: vehicle.deviceId });
    payload.measurementSystem = measurementSystem;
    return payload;
  }
);

const fetchMonitorsThunk = createAsyncThunk("vehicle/monitors", (vehicle) =>
  fetchLatestMonitorData({ deviceId: vehicle.deviceId })
);

const fetchVehicleErrorsThunk = createAsyncThunk("vehicle/errors", (vehicle) =>
  fetchFaults({ deviceId: vehicle.deviceId })
);

const clearDTCThunk = createAsyncThunk(
  "vehicle/clearDTC",
  async (data, thunkAPI) => {
    await clearDTC({ deviceId: data.deviceId });
    thunkAPI.dispatch(fetchVehicleErrorsThunk(data));
  }
);

const createCustomerVehicleThunk = createAsyncThunk(
  "customerVehicles/createVehicle",
  async (data, thunkAPI) => {
    try {
      await createCustomerVehicleRequest(data);
      await thunkAPI.dispatch(showModal({ isOpen: false }));
      await thunkAPI.dispatch(fetchCustomers({ id: data.customerId }));
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.error) ||
        e.message ||
        e;
      await thunkAPI.dispatch(createVehicleError({ message }));
      throw e;
    }
  }
);

const editCustomerVehicleThunk = createAsyncThunk(
  "customerVehicles/editVehicle",
  async (data, thunkAPI) => {
    await editCustomerVehicleRequest(data);
    await thunkAPI.dispatch(fetchCustomerVehiclesThunk(data.customerId));
  }
);

const deleteCustomerVehicleThunk = createAsyncThunk(
  "customerVehicles/deleteVehicle",
  async (data, thunkAPI) => {
    await deleteCustomerVehicleRequest(data);
    await thunkAPI.dispatch(fetchCustomers({ id: data.customerId }));
  }
);

const createDeviceThunk = createAsyncThunk(
  "createDevice",
  async (data, thunkAPI) => {
    try {
      const { _id: customerId } = await createCustomerRequest(data);
      await createCustomerVehicleRequest({ ...data, customerId });
      thunkAPI.dispatch(fetchCustomers({ id: customerId }));
      thunkAPI.dispatch(hideDeviceModal());
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.error) ||
        e.message ||
        e;
      thunkAPI.dispatch(createVehicleError({ message }));
    }
  }
);

const fetchDiagnosticOptionsThunk = createAsyncThunk(
  "customerVehicles/fetchDiagnosticOptions",
  (deviceId) => fetchDiagnosticOptionsRequest(deviceId)
);

const fetchDiagnosticValuesThunk = createAsyncThunk(
  "customerVehicles/fetchDiagnosticValues",
  (data) => fetchDiagnosticValuesRequest(data)
);

const fetchDiagnosticWarningsThunk = createAsyncThunk(
  "customerVehicles/fetchDiagnosticWarnings",
  async (data) => {
    const warnings = await fetchDiagnosticWarningsRequest(data);

    // "traction control disabled" ui warning is using "Enabled" diagnostic and thus requires inversion
    if (warnings.DiagnosticTractionControlSystemEnabledId) {
      warnings.DiagnosticTractionControlSystemEnabledId.data = warnings
        .DiagnosticTractionControlSystemEnabledId.data
        ? 0
        : 1;
    }

    return warnings;
  }
);

const fetchDiagnosticGenericsThunk = createAsyncThunk(
  "customerVehicles/fetchDiagnosticGenerics",
  (data) => fetchDiagnosticWarningsRequest(data)
);

const fetchVehicleAlertsThunk = createAsyncThunk("vehicle/alerts", (vehicle) =>
  fetchVehicleAlerts({ vehicleId: vehicle.id })
);

const fetchVehicleAlertsPastActivityThunk = createAsyncThunk(
  "vehicle/alertsPastActivity",
  (params) => {
    return fetchAlertsPastActivityByVehicleId({
      vehicleId: params.id,
      offset: params.offset,
    });
  }
);

const deleteAlertThunk = createAsyncThunk(
  "customerVehicles/deleteAlert",
  async (data, thunkAPI) => {
    await deleteAlertRequest(data);
    const selectedVehicle = getSelectedVehicle(thunkAPI.getState());
    thunkAPI.dispatch(fetchVehicleAlertsThunk(selectedVehicle));
  }
);

const fetchRecommendedServicesThunk = createAsyncThunk(
  "vehicle/services",
  async (vehicleId, thunkAPI) => {
    const services = await fetchVehicleRecommendedServices({ vehicleId });
    const measurementSystem = getMeasurementSystem(thunkAPI.getState());
    return { services, measurementSystem };
  }
);

const fetchCustomerSteerVehiclesThunk = createAsyncThunk(
  "customerVehicles/fetchSteerAll",
  async ({ steerCustomerId, searchQuery }) => {
    const steerVehicles = await fetchCustomerSteerVehiclesRequest(
      steerCustomerId,
      searchQuery
    );
    return steerVehicles;
  }
);

const fetchServicesHistoryThunk = createAsyncThunk(
  "vehicle/servicesHistory",
  async (vehicleId, thunkAPI) => {
    const { services, currentOdometer } = await fetchVehicleServicesHistory({
      vehicleId,
    });
    const measurementSystem = getMeasurementSystem(thunkAPI.getState());

    return { services, measurementSystem, currentOdometer };
  }
);

const now = new Date();

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    byId: {},
    selectedVehicle: null,
    selectedChartOptions: [],
    selectedChartDateRange: {
      start: String(subDays(now, 7)),
      end: String(now),
    },
    vehicleFilter: "",
    diagnosticChartOptions: [],
    diagnosticChartValues: {},
    diagnosticWarnings: {},
    isShownEditModal: false,
    isShownCreateModal: false,
    isShownDeviceModal: false,
    isShownDeviceAlert: getFromSessionStorage("isShownDeviceAlert") || {},
    error: "",
    isChartLoading: false,
    isWarningsLoading: false,
    generics: {},
    steerVehicles: null,
    isSteerVehiclesLoading: false,
    modalData: null,
  },
  reducers: {
    selectVehicle(state, action) {
      state.selectedVehicle = action.payload;
      state.diagnosticWarnings = {};
    },
    setVehicleFilter(state, action) {
      state.vehicleFilter = action.payload;
    },
    createVehicleError(state, action) {
      state.error = action.payload.message;
    },
    showModal(state, action) {
      if (action.payload.modal === "edit") {
        state.isShownEditModal = action.payload.isOpen;
      } else {
        state.isShownCreateModal = action.payload.isOpen;
      }
    },
    showDeviceModal(state, action) {
      state.isShownDeviceModal = true;
      state.modalData = action.payload;
    },
    hideDeviceModal: (state) => {
      state.isShownDeviceModal = false;
      state.modalData = null;
    },
    selectChartDateRange(state, action) {
      state.selectedChartDateRange = action.payload;
    },
    selectChartOption(state, action) {
      if (!action.payload) {
        state.selectedChartOptions = [];
        state.diagnosticChartValues = {};
        return;
      }
      state.selectedChartOptions = action.payload;
      state.diagnosticChartValues = {};
    },
    setChartLoading(state, { payload }) {
      state.isChartLoading = payload;
    },
    closeAlert(state, action) {
      setToSessionStorage("isShownDeviceAlert", {
        ...state.isShownDeviceAlert,
        [action.payload]: false,
      });
      state.isShownDeviceAlert = {
        ...state.isShownDeviceAlert,
        [action.payload]: false,
      };
    },

    clearError(state) {
      state.error = "";
    },
    clearVehicleData(state, action) {
      delete state.byId[action.payload];
    },
  },
  extraReducers: {
    [fetchCustomerVehiclesThunk.fulfilled](state, action) {
      const vehicles = action.payload;
      vehicles.map(convertToVehicleModel).forEach((vehicle) => {
        state.byId[vehicle.id] = vehicle;
      });
    },
    [fetchVehicleOverviewThunk.pending](state, action) {
      const vehicleId = action.meta.arg;

      state.byId[vehicleId].overview.isLoading = true;
      state.byId[vehicleId].overview.hasError = false;
    },
    [fetchVehicleOverviewThunk.fulfilled](state, action) {
      const vehicleId = action.meta.arg;

      state.byId[vehicleId].overview.isLoading = false;
      state.byId[vehicleId].overview.hasError = false;
      state.byId[vehicleId].overview.data = convertToOverviewModel(
        action.payload
      );
    },
    [fetchVehicleOverviewThunk.rejected](state, action) {
      const vehicleId = action.meta.arg;

      state.byId[vehicleId].overview.isLoading = false;
      state.byId[vehicleId].overview.hasError = true;
      state.byId[vehicleId].overview.data = null;
    },
    [fetchMonitorsThunk.pending](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].monitors.isLoading = true;
      state.byId[id].monitors.hasError = false;
      state.byId[id].monitors.data = [];
    },
    [fetchMonitorsThunk.fulfilled](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].monitors.isLoading = false;
      state.byId[id].monitors.hasError = false;
      state.byId[id].monitors.data = action.payload.map(convertToMonitorModel);
    },
    [fetchMonitorsThunk.rejected](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].monitors.isLoading = false;
      state.byId[id].monitors.hasError = true;
      state.byId[id].monitors.data = [];
    },
    [fetchCoreVehicleDataThunk.pending](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].coreParameters.isLoading = true;
      state.byId[id].coreParameters.hasError = false;
      state.byId[id].coreParameters.data = null;
    },
    [fetchCoreVehicleDataThunk.fulfilled](state, action) {
      const { id } = action.meta.arg;
      state.byId[id].coreParameters.isLoading = false;
      state.byId[id].coreParameters.hasError = false;
      state.byId[id].coreParameters.data = convertToCoreParamsModel(
        action.payload
      );
    },
    [fetchCoreVehicleDataThunk.rejected](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].coreParameters.isLoading = false;
      state.byId[id].coreParameters.hasError = true;
      state.byId[id].coreParameters.data = null;
    },
    [fetchVehicleErrorsThunk.pending](state, action) {
      const { id } = action.meta.arg;

      if (!state.byId[id]) state.byId[id] = { faults: {} };

      state.byId[id].faults.isLoading = true;
      state.byId[id].faults.hasError = false;
      state.byId[id].faults.data = null;
    },
    [fetchVehicleErrorsThunk.fulfilled](state, action) {
      const { id } = action.meta.arg;

      if (!state.byId[id]) return;

      state.byId[id].faults.isLoading = false;
      state.byId[id].faults.hasError = false;
      state.byId[id].faults.data = {
        faults: action.payload.faults.map(convertToFaultModel),
        isClearDTCAvailable: action.payload.isClearDTCAvailable,
      };
    },
    [fetchVehicleErrorsThunk.rejected](state, action) {
      const { id } = action.meta.arg;

      if (!state.byId[id]) return;

      state.byId[id].faults.isLoading = false;
      state.byId[id].faults.hasError = true;
      state.byId[id].faults.data = null;
    },
    [clearDTCThunk.pending](state) {
      state.isClearDTCLoading = true;
    },
    [clearDTCThunk.fulfilled](state) {
      state.isClearDTCLoading = false;
    },
    [clearDTCThunk.rejected](state) {
      state.isClearDTCLoading = false;
    },
    [createCustomerVehicleThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [createCustomerVehicleThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
      state.error = "";
      state.isShownCreateModal = false;
    },
    [createCustomerVehicleThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
      state.isShownCreateModal = true;
    },
    [createDeviceThunk.pending](state) {
      state.isLoading = true;
    },
    [createDeviceThunk.fulfilled](state) {
      state.isLoading = false;
    },
    [createDeviceThunk.rejected](state) {
      state.isLoading = false;
    },
    [editCustomerVehicleThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [editCustomerVehicleThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
      state.isShownEditModal = false;
    },
    [editCustomerVehicleThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
      state.isShownEditModal = true;
    },
    [fetchDiagnosticOptionsThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchDiagnosticOptionsThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.diagnosticChartOptions = action.payload;
      if (action.payload.length) {
        const batteryVoltage = action.payload.find(
          (el) => el.value === "DiagnosticGoDeviceVoltageId"
        );
        if (batteryVoltage) {
          state.selectedChartOptions = [batteryVoltage];
        } else {
          state.selectedChartOptions = [action.payload[0]];
        }
      }
    },
    [fetchDiagnosticOptionsThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
    },
    [fetchDiagnosticValuesThunk.pending](state) {
      state.isChartLoading = true;
      state.hasError = false;
      state.diagnosticChartValues = {};
    },
    [fetchDiagnosticValuesThunk.fulfilled](state, action) {
      state.isChartLoading = false;
      state.hasError = false;
      if (action.payload.length) {
        const receivedDate = new Date(action.payload[0].dateTime).getTime();
        const startDate = new Date(action.meta.arg.from).getTime();
        const endDate = new Date(action.meta.arg.to).getTime();
        if (startDate <= receivedDate && receivedDate <= endDate) {
          state.diagnosticChartValues = {
            ...state.diagnosticChartValues,
            [action.payload[0].diagnostic.id]: action.payload,
          };
        }
      } else {
        state.diagnosticChartValues = {
          ...state.diagnosticChartValues,
          [action.meta.arg.diagnosticId]: [],
        };
      }
    },
    [fetchDiagnosticValuesThunk.rejected](state) {
      state.isChartLoading = false;
      state.hasError = true;
    },
    [fetchDiagnosticWarningsThunk.pending](state) {
      state.isWarningsLoading = true;
      state.hasError = false;
    },
    [fetchDiagnosticWarningsThunk.fulfilled](state, action) {
      state.isWarningsLoading = false;
      state.hasError = false;
      state.diagnosticWarnings = action.payload;
    },
    [fetchDiagnosticWarningsThunk.rejected](state) {
      state.isWarningsLoading = false;
      state.hasError = true;
    },
    [fetchDiagnosticGenericsThunk.pending](state) {
      state.isWarningsLoading = true;
      state.hasError = false;
    },
    [fetchDiagnosticGenericsThunk.fulfilled](state, action) {
      state.isWarningsLoading = false;
      state.hasError = false;
      state.generics = action.payload;
    },
    [fetchDiagnosticGenericsThunk.rejected](state) {
      state.isWarningsLoading = false;
      state.hasError = true;
    },
    [fetchVehicleAlertsThunk.pending](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].alerts.isLoading = true;
      state.byId[id].alerts.hasError = false;
      state.byId[id].alerts.data = [];
    },
    [fetchVehicleAlertsThunk.fulfilled](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].alerts.isLoading = false;
      state.byId[id].alerts.hasError = false;
      state.byId[id].alerts.data = action.payload;
    },
    [fetchVehicleAlertsThunk.rejected](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].alerts.isLoading = false;
      state.byId[id].alerts.hasError = true;
      state.byId[id].alerts.data = [];
    },
    [fetchVehicleAlertsPastActivityThunk.pending](state, action) {
      const { id, offset } = action.meta.arg;

      if (offset === 0) {
        state.byId[id].alerts.isLoading = true;
        state.byId[id].alerts.hasError = false;
        state.byId[id].alerts.pastActivity = [];
        state.byId[id].alerts.hasMore = true;
      }
    },
    [fetchVehicleAlertsPastActivityThunk.fulfilled](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].alerts.isLoading = false;
      state.byId[id].alerts.hasError = false;

      state.byId[id].alerts.pastActivity = [
        ...state.byId[id].alerts.pastActivity,
        ...action.payload,
      ];

      if (!action.payload.length) {
        state.byId[id].alerts.hasMore = false;
      }
    },
    [fetchVehicleAlertsPastActivityThunk.rejected](state, action) {
      const { id } = action.meta.arg;

      state.byId[id].alerts.isLoading = false;
      state.byId[id].alerts.hasError = true;
      state.byId[id].alerts.pastActivity = [];
      state.byId[id].alerts.hasMore = false;
    },
    [fetchRecommendedServicesThunk.pending](state, action) {
      const id = action.meta.arg;

      state.byId[id].services.isLoading = true;
      state.byId[id].services.hasError = false;
      state.byId[id].services.data = [];
    },
    [fetchRecommendedServicesThunk.fulfilled](state, action) {
      const id = action.meta.arg;

      state.byId[id].services.isLoading = false;
      state.byId[id].services.hasError = false;
      state.byId[id].services.data = convertToRecommendedServiceModel(
        action.payload
      );
    },
    [fetchRecommendedServicesThunk.rejected](state, action) {
      const id = action.meta.arg;

      state.byId[id].services.isLoading = false;
      state.byId[id].services.hasError = true;
      state.byId[id].services.data = [];
    },
    [fetchCustomerSteerVehiclesThunk.pending](state) {
      state.steerVehicles = null;
      state.isSteerVehiclesLoading = true;
    },
    [fetchCustomerSteerVehiclesThunk.fulfilled](state, action) {
      state.steerVehicles = action.payload;
      state.isSteerVehiclesLoading = false;
    },
    [fetchCustomerSteerVehiclesThunk.rejected](state) {
      state.steerVehicles = null;
      state.isSteerVehiclesLoading = false;
    },
    [fetchServicesHistoryThunk.pending](state, action) {
      const id = action.meta.arg;

      state.byId[id].servicesHistory.isLoading = true;
      state.byId[id].servicesHistory.hasError = false;
      state.byId[id].servicesHistory.data = [];
    },
    [fetchServicesHistoryThunk.fulfilled](state, action) {
      const id = action.meta.arg;

      state.byId[id].servicesHistory.isLoading = false;
      state.byId[id].servicesHistory.hasError = false;
      state.byId[id].servicesHistory.data = convertToServiceHistoryModel(
        action.payload
      );
    },
    [fetchServicesHistoryThunk.rejected](state, action) {
      const id = action.meta.arg;

      state.byId[id].servicesHistory.isLoading = false;
      state.byId[id].servicesHistory.hasError = true;
      state.byId[id].servicesHistory.data = [];
    },
  },
});

export { fetchCustomerVehiclesThunk as fetchCustomerVehicles };
export { fetchVehicleOverviewThunk as fetchVehicleOverview };
export { fetchMonitorsThunk as fetchVehicleMonitors };
export { fetchCoreVehicleDataThunk as fetchCoreVehicleData };
export { fetchVehicleErrorsThunk as fetchVehicleErrors };
export { createCustomerVehicleThunk as createCustomerVehicle };
export { editCustomerVehicleThunk as editCustomerVehicle };
export { deleteCustomerVehicleThunk as deleteCustomerVehicle };
export { createDeviceThunk as createDevice };
export { fetchDiagnosticOptionsThunk as fetchDiagnosticOptions };
export { fetchDiagnosticValuesThunk as fetchDiagnosticValues };
export { fetchDiagnosticWarningsThunk as fetchDiagnosticWarnings };
export { fetchVehicleAlertsThunk as fetchVehicleAlerts };
export { fetchVehicleAlertsPastActivityThunk as fetchVehicleAlertsPastActivity };
export { deleteAlertThunk as deleteAlert };
export { clearDTCThunk as clearDTC };
export { fetchRecommendedServicesThunk as fetchVehicleRecommendedServices };
export { fetchCustomerSteerVehiclesThunk as fetchCustomerSteerVehicles };
export { fetchServicesHistoryThunk as fetchVehicleServicesHistory };

const { actions, reducer } = vehiclesSlice;
export const {
  selectVehicle,
  setVehicleFilter,
  createVehicleError,
  clearError,
  showModal,
  showDeviceModal,
  hideDeviceModal,
  closeAlert,
  selectChartOption,
  selectChartDateRange,
  setChartLoading,
  clearVehicleData,
} = actions;
export default reducer;

function convertToVehicleModel({
  _id,
  name,
  deviceId,
  phoneNumber,
  description: notes,
  isOdometerChangeAvailable,
}) {
  return {
    id: _id,
    name,
    deviceId,
    phoneNumber,
    notes,
    isOdometerChangeAvailable,
    overview: {
      isLoading: false,
      hasError: false,
      data: null,
    },
    monitors: {
      isLoading: false,
      hasError: false,
      data: [],
    },
    coreParameters: {
      isLoading: false,
      hasError: false,
      data: null,
    },
    faults: {
      isLoading: false,
      hasError: false,
      data: null,
    },
    alerts: {
      isLoading: false,
      hasError: false,
      data: [],
      pastActivity: [],
    },
    services: {
      isLoading: false,
      hasError: false,
      data: [],
    },
    servicesHistory: {
      isLoading: false,
      hasError: false,
      data: null,
    },
  };
}

function convertToOverviewModel({
  device: { vehicleIdentificationNumber, serialNumber },
  deviceStatus: { isDeviceCommunicating, lastTimeCommunicated },
  ignition,
  generalVehicleWarning,
  odometer: { value, updatedAt } = {},
  pidCount,
  fuelType,
  tankSize,
  detectedProtocols,
  vinData,
  measurementSystem,
}) {
  const isMeasurementSystemImperial = measurementSystem === "imperial";
  return {
    vin: vehicleIdentificationNumber,
    serialNumber,
    isDeviceCommunicating,
    lastTimeCommunicated,
    ignition,
    generalVehicleWarning,
    odometer: {
      value: isMeasurementSystemImperial
        ? convertMetersToMiles(value)
        : convertMetersToKilometers(value),
      updatedAt,
    },
    pidCount,
    fuelType,
    tankSize: isMeasurementSystemImperial
      ? convertLitersToGallons(tankSize)
      : tankSize,
    detectedProtocols,
    vinData,
  };
}

function convertToMonitorModel({
  name,
  isAvailable,
  isCompleted,
  completionTime,
}) {
  return { name, isAvailable, isCompleted, completionTime };
}

function convertToCoreParamsModel({
  tirePressureFrontRight,
  tirePressureFrontLeft,
  tirePressureRearRight,
  tirePressureRearLeft,
  coolantTemperature,
  crankingVoltage,
  fuelLevelPercentage,
  transmissionOilTemperature,
  fuelFilterLifeRemaining,
  engineOilLifeRemaining,
  last30DaysMetrics,
  engineOperationalTime,
  measurementSystem,
}) {
  const isMeasurementSystemImperial = measurementSystem === "imperial";
  const tirePressure = {};
  if (tirePressureFrontRight && tirePressureFrontRight.value) {
    tirePressure.fr = isMeasurementSystemImperial
      ? convertPascalsToPsi(tirePressureFrontRight.value)
      : convertPascalsToBar(tirePressureFrontRight.value);
  }
  if (tirePressureFrontLeft && tirePressureFrontLeft.value) {
    tirePressure.fl = isMeasurementSystemImperial
      ? convertPascalsToPsi(tirePressureFrontLeft.value)
      : convertPascalsToBar(tirePressureFrontLeft.value);
  }
  if (tirePressureRearRight && tirePressureRearRight.value) {
    tirePressure.rr = isMeasurementSystemImperial
      ? convertPascalsToPsi(tirePressureRearRight.value)
      : convertPascalsToBar(tirePressureRearRight.value);
  }
  if (tirePressureRearLeft && tirePressureRearLeft.value) {
    tirePressure.rl = isMeasurementSystemImperial
      ? convertPascalsToPsi(tirePressureRearLeft.value)
      : convertPascalsToBar(tirePressureRearLeft.value);
  }

  const {
    distanceDriven,
    fuelUsed,
    idleFuelUsed,
    litersPer100Km,
    disanceSinceCodesCleared,
    distanseWithMilOn,
    timeSinceCodesCleared,
    timeSinceMilOn,
    warmsup,
  } = last30DaysMetrics;

  return {
    tirePressure,
    coolantTemperature: isMeasurementSystemImperial
      ? convertCelsiusToFahrenheit(
          coolantTemperature && coolantTemperature.value
        )
      : (coolantTemperature && coolantTemperature.value) || null,
    crankingVoltage: crankingVoltage && crankingVoltage.value,
    fuelLevelPercentage:
      fuelLevelPercentage && fuelLevelPercentage.value
        ? Math.round(fuelLevelPercentage.value)
        : null,
    transmissionOilTemperature: isMeasurementSystemImperial
      ? convertCelsiusToFahrenheit(
          transmissionOilTemperature && transmissionOilTemperature.value
        )
      : (transmissionOilTemperature && transmissionOilTemperature.value) ||
        null,
    fuelFilterLifeRemaining:
      fuelFilterLifeRemaining && fuelFilterLifeRemaining.value,
    engineOilLifeRemaining:
      engineOilLifeRemaining && engineOilLifeRemaining.value,
    distanceDriven: isMeasurementSystemImperial
      ? convertMetersToMiles(distanceDriven)
      : convertMetersToKilometers(distanceDriven),
    disanceSinceCodesCleared: isMeasurementSystemImperial
      ? convertMetersToMiles(disanceSinceCodesCleared)
      : convertMetersToKilometers(disanceSinceCodesCleared),
    distanseWithMilOn: isMeasurementSystemImperial
      ? convertMetersToMiles(distanseWithMilOn)
      : convertMetersToKilometers(distanseWithMilOn),
    fuelUsed: isMeasurementSystemImperial
      ? convertLitersToGallons(fuelUsed)
      : Math.round(fuelUsed * 10) / 10,
    idleFuelUsed: isMeasurementSystemImperial
      ? convertLitersToGallons(idleFuelUsed)
      : Math.round(idleFuelUsed * 10) / 10,
    mpg: isMeasurementSystemImperial
      ? convertlitersPer100KmToMpg(litersPer100Km)
      : litersPer100Km,
    engineOperationalTime: engineOperationalTime
      ? engineOperationalTime.value
      : null,
    timeSinceCodesCleared,
    timeSinceMilOn,
    warmsup,
  };
}

function convertToFaultModel({
  diagnosticId,
  description,
  code,
  controller,
  occurences,
  source,
  isDtcCode,
  engineType,
  freezeFrame,
}) {
  return {
    diagnosticId,
    description,
    code,
    controller,
    occurences,
    isDtcCode,
    source: source ? source.replace(/^Source/g, "").replace(/Id$/g, "") : null,
    engineType: engineType
      ? engineType.replace(/^EngineType/g, "").replace(/Id$/g, "")
      : null,
    freezeFrame,
  };
}

function convertToRecommendedServiceModel({ services, measurementSystem }) {
  const isMeasurementSystemImperial = measurementSystem === "imperial";
  return services.map((service) => {
    if (!service.odometer) return service;
    return {
      ...service,
      odometer: isMeasurementSystemImperial
        ? convertMetersToMiles(service.odometer)
        : convertMetersToKilometers(service.odometer),
      initialOdometer: isMeasurementSystemImperial
        ? convertMetersToMiles(service.initialOdometer)
        : convertMetersToKilometers(service.initialOdometer),
    };
  });
}

function convertToServiceHistoryModel({
  services,
  measurementSystem,
  currentOdometer,
}) {
  const isMeasurementSystemImperial = measurementSystem === "imperial";
  return services.map(({ name, date, odometer }) => {
    const daysAgo = date
      ? Math.floor((Date.now() - date) / (24 * 60 * 60 * 1000))
      : "N/A";
    const distanceAgo =
      odometer && currentOdometer && currentOdometer >= odometer
        ? isMeasurementSystemImperial
          ? convertMetersToMiles(currentOdometer - odometer)
          : convertMetersToKilometers(currentOdometer - odometer)
        : "N/A";

    return {
      name,
      daysAgo,
      distanceAgo,
    };
  });
}
