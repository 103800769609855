import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { format } from "date-fns";

import { ALL_LOCATIONS } from "../../../../constants";

import { joinShopAddress } from "../../../../utils";

import {
  getSelectedVehicle,
  getIsGeneralVehicleDataLoading,
  getVehicleState,
} from "../../vehicles.selectors";
import {
  getSelectedCustomer,
  getSelectedCustomerVehicles,
} from "../../../customers/customers.selectors";
import {
  showModal,
  deleteCustomerVehicle,
  selectVehicle,
} from "../../vehicles.slice";
import {
  getSelectedShopId,
  getShopsById,
} from "../../../shops/shops.selectors";

import Spinner from "../../../../components/spinner/Spinner";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DisconnectIcon } from "../../../../assets/icons/disconnect.svg";

import classes from "./GeneralVehicleData.module.scss";
import dropdownClasses from "../../../../styles/dropdown.module.scss";
import CoreVehicleParameters from "./CoreVehicleParameters";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { VehicleModal } from "../vehicle-modal/VehicleModal";
import { RemoveVehicleModal } from "../confirm-remove-vehicle-modal/RemoveVehicleModal";
import DeviceConnectedIcon from "../../../../assets/icons/device-connected.svg";
import DeviceDisconnectedIcon from "../../../../assets/icons/device-disconnected.svg";
import IgnitionOnIcon from "../../../../assets/icons/ignition-on.svg";
import IgnitionOffIcon from "../../../../assets/icons/ignition-off.svg";

export default function GeneralVehicleData({ className }) {
  const selectedVehicle = useSelector(getSelectedVehicle);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const isGeneralDataLoading = useSelector(getIsGeneralVehicleDataLoading);
  const vehicleState = useSelector(getVehicleState);
  const shops = useSelector(getShopsById);
  const selectedShopId = useSelector(getSelectedShopId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShownRemoveModal, setIsShownRemoveModal] = useState(false);
  const customerVehicles = useSelector(getSelectedCustomerVehicles);
  const isLastVehicle = customerVehicles && customerVehicles.length === 1;

  const isMeasurementSystemImperial =
    selectedCustomer && selectedCustomer.measurementSystem === "imperial";

  const shopData = useMemo(() => {
    const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

    if (isAllLocations && selectedCustomer) {
      const shop = shops[selectedCustomer.shopId];
      const shopName = shop?.name;
      const shopAddress = joinShopAddress(shop, false);

      return { name: shopName, address: shopAddress };
    }
    return null;
  }, [selectedShopId, selectedCustomer]);

  const handleToggle = () =>
    dispatch(
      showModal({ modal: "edit", isOpen: !vehicleState.isShownEditModal })
    );

  const [isDropdownOpened, setDropdownOpened] = useState(false);

  if (isGeneralDataLoading) {
    return (
      <div
        className={cn(classes.SpinnerWrapper, "position-relative w-100 h-100")}
      >
        <Spinner />
      </div>
    );
  }

  if (!selectedVehicle || !selectedVehicle.overview.data) {
    return null;
  }

  const {
    vin,
    serialNumber,
    isDeviceCommunicating,
    ignition,
    generalVehicleWarning,
    odometer: { value },
    pidCount,
    fuelType,
    tankSize,
    vinData,
    detectedProtocols,
    lastTimeCommunicated,
  } = selectedVehicle.overview.data;

  const { year, make, model, transmissionName, engineName, style } =
    vinData || {};
  const vehicleName = vinData
    ? `${year} ${make} ${model}`
    : selectedVehicle.name;

  const odometerValue = Number.isFinite(value)
    ? value.toLocaleString() + `${isMeasurementSystemImperial ? " mi" : " km"}`
    : "N/A";

  const lastConnectedValue =
    typeof lastTimeCommunicated === "string"
      ? format(new Date(lastTimeCommunicated), "MMM dd, y")
      : "N/A";

  const handleDeleteVehicle = () => {
    dispatch(
      deleteCustomerVehicle({
        id: selectedVehicle.id,
        customerId: selectedCustomer.id,
        serialNumber,
        deviceId: selectedVehicle.deviceId,
      })
    );
    dispatch(selectVehicle(null));
    history.push(`/${selectedShopId}/customer/${selectedCustomer.id}`);
  };

  return (
    <div className={cn(classes.GeneralVehicleData, className)}>
      <div className={cn(classes.GeneralVehicleData__vehicleName, "mb-3")}>
        <div>
          {vehicleName}
          <div className={classes.GeneralVehicleData__vehicleStyle}>
            {vinData && style}
          </div>
        </div>

        <VehicleDropdown
          openEditModal={handleToggle}
          deleteVehicle={handleDeleteVehicle}
          isOpen={isDropdownOpened}
          toggle={() => setDropdownOpened(!isDropdownOpened)}
          openRemoveModal={() => setIsShownRemoveModal(!isShownRemoveModal)}
        />
      </div>
      {shopData && (
        <div
          className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}
        >
          <div
            className={cn(
              classes.GeneralVehicleData__recordLabel,
              "text-left",
              "flex-grow-1"
            )}
          >
            Shop Location
          </div>
          <div
            className={cn(
              classes.GeneralVehicleData__recordValue,
              "text-right",
              "flex-grow-1",
              classes["GeneralVehicleData__recordValue--shop"]
            )}
          >
            <div className={classes.name}>{shopData.name}</div>
            <div className={classes.address}>{shopData.address}</div>
          </div>
        </div>
      )}
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Device
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {isDeviceCommunicating ? (
            <div>
              <img
                src={DeviceConnectedIcon}
                alt="connected"
                className="pr-1 align-text-bottom"
              />
              <span
                className={
                  classes["GeneralVehicleData__recordValue--deviceConnected"]
                }
              >
                Connected
              </span>
            </div>
          ) : (
            <div>
              <img
                src={DeviceDisconnectedIcon}
                alt="disconnected"
                className="pr-1 align-text-bottom"
              />
              <span
                className={
                  classes["GeneralVehicleData__recordValue--deviceDisconnected"]
                }
              >
                Disconnected
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Device Serial Number
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {serialNumber}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Vehicle State
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {typeof ignition !== "boolean" ? (
            "N/A"
          ) : ignition ? (
            <div>
              <img
                src={IgnitionOnIcon}
                alt="on"
                className="pr-1 align-text-bottom"
              />
              <span
                className={
                  classes["GeneralVehicleData__recordValue--ignitionOn"]
                }
              >
                Driving
              </span>
            </div>
          ) : (
            <div>
              <img
                src={IgnitionOffIcon}
                alt="disconnected"
                className={cn(
                  classes["GeneralVehicleData__recordValue--ignitionOffIcon"],
                  "pr-1 align-text-bottom"
                )}
              />
              <span
                className={
                  classes["GeneralVehicleData__recordValue--ignitionOff"]
                }
              >
                Engine Off
              </span>
            </div>
          )}
        </div>
      </div>
      {/* TODO Uncomment later */}
      {/* <div className="d-flex pt-3">
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Engine
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          N/A
        </div>
      </div> */}
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          General Vehicle Warning Light
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {generalVehicleWarning ? "ON" : "OFF"}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          VIN
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {vin}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Odometer
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {odometerValue}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Last Connected
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {lastConnectedValue}
        </div>
      </div>

      {fuelType !== undefined && (
        <div
          className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}
        >
          <div
            className={cn(
              classes.GeneralVehicleData__recordLabel,
              "text-left",
              "flex-grow-1"
            )}
          >
            Fuel
          </div>
          <div
            className={cn(
              classes.GeneralVehicleData__recordValue,
              "text-right",
              "flex-grow-1"
            )}
          >
            {fuelType === 1 && "Gasoline"}
            {fuelType === 0 && "Diesel"}
            {typeof fuelType === "string" && fuelType}
          </div>
        </div>
      )}

      {tankSize && (
        <div
          className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}
        >
          <div
            className={cn(
              classes.GeneralVehicleData__recordLabel,
              "text-left",
              "flex-grow-1"
            )}
          >
            Fuel Tank Size
          </div>
          <div
            className={cn(
              classes.GeneralVehicleData__recordValue,
              "text-right",
              "flex-grow-1"
            )}
          >
            {tankSize + `${isMeasurementSystemImperial ? " gal" : " l"}`}
          </div>
        </div>
      )}
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          OBD Protocols
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {!detectedProtocols.length
            ? "N/A"
            : detectedProtocols.map((protocol) => (
                <React.Fragment key={protocol.name}>
                  <span>
                    {protocol.name
                      .replace(/ engine protocol detected$/, "")
                      .replace(/ protocol detected$/, "")}
                  </span>
                  <br />
                </React.Fragment>
              ))}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          OBD-II PID Count
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {pidCount ? pidCount.value : "N/A"}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Transmission
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {vinData && transmissionName ? transmissionName : "N/A"}
        </div>
      </div>
      <div className={cn("d-flex pt-3", classes.GeneralVehicleData__recordBox)}>
        <div
          className={cn(
            classes.GeneralVehicleData__recordLabel,
            "text-left",
            "flex-grow-1"
          )}
        >
          Engine
        </div>
        <div
          className={cn(
            classes.GeneralVehicleData__recordValue,
            "text-right",
            "flex-grow-1"
          )}
        >
          {vinData ? engineName : "N/A"}
        </div>
      </div>
      <div className={cn(classes.OverviewDelimiter)} />
      <CoreVehicleParameters />
      {vehicleState.isShownEditModal && (
        <VehicleModal isEdit={true} toggle={handleToggle} />
      )}
      {isShownRemoveModal && (
        <RemoveVehicleModal
          isOpen={isShownRemoveModal}
          toggle={() => setIsShownRemoveModal(!isShownRemoveModal)}
          onClick={handleDeleteVehicle}
          isLastVehicle={isLastVehicle}
          vehicleName={vehicleName}
        />
      )}
    </div>
  );
}

function VehicleDropdown({ isOpen, toggle, openEditModal, openRemoveModal }) {
  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle
          tag="div"
          className={cn(dropdownClasses.Dropdown__Toggle, "pt-9")}
        >
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
        </DropdownToggle>
        <DropdownMenu className={dropdownClasses.Dropdown__Menu}>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={openEditModal}
          >
            <EditIcon className={dropdownClasses.Dropdown__Icon} />
            <div>Edit Vehicle Info</div>
          </DropdownItem>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={openRemoveModal}
          >
            <DisconnectIcon className={dropdownClasses.Dropdown__Icon} />
            <div>Disconnect Vehicle</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
