import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTenants as fetchTenantsRequest } from "../../services/api.service";
import { setToLocalStorage, getFromLocalStorage } from "../../utils";
import { getFirstAvailableTenant } from "../../services/tenant.service";

import { keyBy } from "lodash";

const fetchTenantsThunk = createAsyncThunk(
  "tenants/fetchAll",
  async ({ searchQuery, takeFrom } = {}, thunkAPI) => {
    const { tenants, hasMore } = await fetchTenantsRequest({
      searchQuery,
      takeFrom,
    });
    thunkAPI.dispatch(setTenantsShown(true));
    return { tenants, hasMore };
  }
);

const tenantsSlice = createSlice({
  name: "tenants",
  initialState: {
    byId: null,
    hasMore: null,
    isLoading: false,
    hasError: false,
    selectedTenant: null,
    isFirstRender: true,
  },
  reducers: {
    selectTenant(state, action) {
      state.selectedTenant = action.payload;
      setToLocalStorage(`selectedTenant`, action.payload);
      state.isTenantsShown = false;
    },
    setTenantsShown(state, action) {
      state.isTenantsShown = action.payload;
    },
    setIsFirstTenantsRender(state, action) {
      state.isFirstRender = action.payload;
    },
  },
  extraReducers: {
    [fetchTenantsThunk.pending](state, action) {
      const isLoadingMore = action.meta.arg?.takeFrom > 1;
      if (!isLoadingMore) {
        state.byId = null;
        state.hasMore = null;
        state.isLoading = true;
        state.hasError = false;
      }
    },
    [fetchTenantsThunk.fulfilled](state, action) {
      const isLoadingMore = action.meta.arg?.takeFrom > 1;
      state.isLoading = false;
      state.hasError = false;
      const { tenants, hasMore } = action.payload;
      state.hasMore = hasMore;
      state.byId = isLoadingMore
        ? { ...state.byId, ...keyBy(tenants, "id") }
        : keyBy(tenants, "id");
      state.selectedTenant =
        state.isFirstRender &&
        (getFromLocalStorage("selectedTenant") ||
          getFirstAvailableTenant(tenants));
    },
    [fetchTenantsThunk.rejected](state) {
      state.byId = null;
      state.hasMore = null;
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export { fetchTenantsThunk as fetchTenants };

const { actions, reducer } = tenantsSlice;

export const { selectTenant, setTenantsShown, setIsFirstTenantsRender } =
  actions;
export default reducer;
