import React, { useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import onClickOutside from "react-onclickoutside";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";

import { fetchTenants, setIsFirstTenantsRender } from "../tenants.slice";

import SearchField from "../../shops/HeaderShopToggle/components/SearchField/SearchField";
import RequestResult from "../../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import { NoResultIcon } from "../../../assets/icons";
import styles from "../../shops/HeaderShopToggle/components/HeaderShopsList/HeaderShopsList.module.scss";
import { ShopItem } from "../../shops/HeaderShopToggle/components/HeaderShopsList/components/ShopItem/ShopItem";
import Typography from "../../../components/typography/Typography";
import Loader from "../../../components/loader/Loader";

function HeaderTenantsList({
  tenants,
  hasMore,
  selectedTenantId,
  onTenantClick,
  setDropdownOpen,
  toggleDisplayPosition,
  isLoading,
}) {
  HeaderTenantsList.handleClickOutside = () => {
    setDropdownOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");
  const steerTenantsSearchQuery = useRef("");
  const dispatch = useDispatch();

  const handleTenantSearch = (searchValue) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    setSearchValue(searchValue);
    dispatch(setIsFirstTenantsRender(false));
    if (steerTenantsSearchQuery.current === searchValue) return;
    steerTenantsSearchQuery.current = searchValue;
    dispatch(fetchTenants({ searchQuery: normalizedSearchValue, takeFrom: 1 }));
  };

  const handleQueryMore = () => {
    dispatch(setIsFirstTenantsRender(false));
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    dispatch(
      fetchTenants({
        searchQuery: normalizedSearchValue,
        takeFrom: tenants ? tenants.length + 1 : 1,
      })
    );
  };

  const isTenantsAvailable = tenants && tenants.length > 0;

  return (
    <div
      className={cn(
        styles.HeaderShopsList,
        { [styles.rightPosition]: toggleDisplayPosition === "right" },
        { [styles.leftPosition]: toggleDisplayPosition === "left" },
        "absolute border-radius-7 box-shadow-dark pt-10"
      )}
    >
      <Typography fontSize="xl" fontWeight="bold" className="ml-20 mt-10">
        Tenants
      </Typography>

      <div className={styles.searchField}>
        <SearchField
          placeholder="Search for tenant..."
          value={searchValue}
          onChange={handleTenantSearch}
          inputDelay={800}
        />
      </div>

      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader className={styles.loader} />
        </div>
      )}

      {!isLoading && (
        <div className={styles.scrollbar} id="scrollbar">
          <ReactTooltip className={styles.tooltip} id="tooltipTenant" />
          {isTenantsAvailable && (
            <InfiniteScroll
              dataLength={tenants.length}
              next={handleQueryMore}
              hasMore={hasMore}
              scrollableTarget="scrollbar"
              scrollThreshold="10px"
            >
              {tenants.map((tenant) => {
                const { id, name, locationsNumber } = tenant;

                return (
                  <ShopItem
                    key={id}
                    name={name}
                    isSelected={id === selectedTenantId}
                    onShopClick={() => onTenantClick(tenant)}
                    isDisabled={locationsNumber === 0}
                  />
                );
              })}
            </InfiniteScroll>
          )}
          {hasMore && (
            <div className={styles.loadMoreWrapper}>
              <Loader className={styles.loader} />
            </div>
          )}
          {!isTenantsAvailable && (
            <RequestResult
              wrapperClassName={cn(styles.noData)}
              image={<NoResultIcon width={100} height={100} />}
              title="No results found"
              message={
                "We can't find any results matching your search.\nPlease check spelling or try other keywords."
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

HeaderTenantsList.propTypes = {
  tenants: PropTypes.array,
  selectedTenantId: PropTypes.string,
  onTenantClick: PropTypes.func,
  setDropdownOpen: PropTypes.func,
  toggleDisplayPosition: PropTypes.oneOf(["right", "left"]),
  isLoading: PropTypes.bool,
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderTenantsList.handleClickOutside,
};

export default onClickOutside(HeaderTenantsList, clickOutsideConfig);
