import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import cn from "classnames";
import { debounce } from "lodash";

import styles from "./Vehicles.module.scss";
import stylesRequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";

import {
  convertMetersToMiles,
  replaceSpaceToUnderscore,
  joinShopAddress,
} from "../../utils";
import { getRoutePrefix } from "../../services/env.service";

import { getShopsById, getSelectedShopId } from "../shops/shops.selectors";
import { getCustomers } from "../customers/customers.selectors";
import {
  getVehiclesColumns,
  getVehiclesFilters,
  getAdminStatus,
} from "../../App/ui.slice";
import { setShopIdHeader } from "../../services/api.service";
import { ALL_LOCATIONS } from "../../constants";

import { SortArrowDownIcon, NoResultIcon } from "../../assets/icons";
import EngineYellowIcon from "../../assets/icons/engine-yellow.svg";
import EngineGreenIcon from "../../assets/icons/engine-green.svg";
import DefaultCarAvatar from "../../assets/icons/default-car.png";

import RequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import SearchField from "../shops/HeaderShopToggle/components/SearchField/SearchField";
import Spinner from "../../components/spinner/Spinner";
import InfoTooltip from "../../components/tooltip/InfoTooltip";
import EngineIcon from "../../components/engine-icon/EngineIcon";
import VehicleDTC from "../../components/tooltip/VehicleDTC";
import VehicleWarnings from "../../components/tooltip/VehicleWarnings";
import VehiclePSI from "../../components/tooltip/VehiclePSI";
import Filters from "./Filters";
import CustomizeColumns from "./CustomizeColumns";

const TABLE_SORT_ORDER = [
  "check",
  "warnings",
  "dtcs",
  "others",
  "service",
  "psi",
  "vehicle",
];

const FILTER_FUNCTIONS = {
  serviceMiles: (vehicle, filterData) => {
    return (
      typeof vehicle.service === "number" &&
      vehicle.service >= filterData.data[0] &&
      vehicle.service <= filterData.data[1]
    );
  },
  serviceDays: (vehicle, filterData) => {
    return (
      typeof vehicle.serviceDays === "number" &&
      vehicle.serviceDays >= filterData.data[0] &&
      vehicle.serviceDays <= filterData.data[1]
    );
  },
  warnings: (vehicle, filterData) => !!vehicle.warnings === filterData.data,
  others: (vehicle, filterData) => !!vehicle.others === filterData.data,
  checkEngine: (vehicle, filterData) => vehicle.check === filterData.data,
  psi: (vehicle, filterData) => {
    return (
      typeof vehicle.psi === "number" &&
      vehicle.psi >= filterData.data[0] &&
      vehicle.psi <= filterData.data[1]
    );
  },
};

export default function Vehicles() {
  const isAdmin = useSelector(getAdminStatus);
  const selectedShopId = useSelector(getSelectedShopId);
  const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

  const [searchString, setSearchString] = useState("");
  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchString(value);
    }, 300),
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Vehicles with Installed Devices</div>
      <div className={styles.controls}>
        <SearchField
          className={styles.search}
          placeholder="Search for vehicle or customer..."
          placeholderMobile="Search..."
          onChange={handleSearchChange}
        />
        <Filters />
        <CustomizeColumns isAllLocations={isAllLocations} />
      </div>
      <div className={styles.panel}>
        <Table searchString={searchString} isAllLocations={isAllLocations} />
      </div>
    </div>
  );
}

function Table({ searchString, isAllLocations }) {
  const customers = useSelector(getCustomers);
  const filters = useSelector(getVehiclesFilters);
  const columns = useSelector(getVehiclesColumns);
  const shops = useSelector(getShopsById);

  const [vehicles, setVehicles] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState(null);
  const [sortingConfig, setSortingConfig] = useState({
    fields: TABLE_SORT_ORDER,
    direction: "desc",
  });
  const [sortedVehicles, setSortedVehicles] = useState(null);
  const [shownVehicles, setShownVehicles] = useState(null);

  useEffect(() => {
    const vehicles = customers.reduce((acc, customer) => {
      const shop = shops[customer.shopId];
      const shopName = shop?.name;
      const shopAddress = joinShopAddress(shop, false);

      const customerVehicles = Object.entries(customer.vehiclesData).map(
        ([vehicleId, vehicleData]) => {
          const service =
            vehicleData.lastService?.odometerSince >= 0
              ? convertMetersToMiles(vehicleData.lastService.odometerSince)
              : "N/A";
          const serviceDays =
            vehicleData.lastService?.dateSince >= 0
              ? vehicleData.lastService.dateSince / (24 * 60 * 60 * 1000)
              : null;

          return {
            customerId: customer.id,
            customer: customer.name,
            shopId: customer.shopId,
            shopName,
            shopAddress,
            vehicleId,
            vehicle: vehicleData.name,
            deviceId: vehicleData.deviceId,
            vinData: vehicleData.vinData,
            service,
            serviceDays,
            serviceData: vehicleData.lastService,
            check: vehicleData.checkEngine,
            dtcs: vehicleData.dtcCount,
            warnings: vehicleData.warnings,
            warningsData: vehicleData.warningsData,
            others: vehicleData.otherAlerts,
            othersData: vehicleData.otherAlertsData,
            psi: vehicleData.lowestPSI,
            tirePressures: vehicleData.tirePressures,
          };
        }
      );
      return [...acc, ...customerVehicles];
    }, []);

    setVehicles(vehicles);
  }, [customers]);

  useEffect(() => {
    if (!vehicles) return;
    const filteredVehicles = [...vehicles].filter((vehicle) => {
      for (const [filterId, filterData] of Object.entries(filters)) {
        if (filterData.isActive) {
          const isFiltered = FILTER_FUNCTIONS[filterId](vehicle, filterData);
          if (!isFiltered) return false;
        }
      }
      return true;
    });

    setFilteredVehicles(filteredVehicles);
  }, [filters, vehicles]);

  useEffect(() => {
    if (!filteredVehicles) return;

    const sorted = [...filteredVehicles].sort((a, b) => {
      const { fields, direction } = sortingConfig;
      for (const field of fields) {
        if (a[field] === b[field]) continue;
        if (a[field] === "N/A" || a[field] === undefined) {
          return direction === "asc" ? -1 : 1;
        }
        if (b[field] === "N/A" || b[field] === undefined) {
          return direction === "asc" ? 1 : -1;
        }
        return direction === "asc"
          ? (a[field] > b[field] && 1) || -1
          : (b[field] > a[field] && 1) || -1;
      }
      return 0;
    });

    setSortedVehicles(sorted);
  }, [sortingConfig, filteredVehicles]);

  useEffect(() => {
    if (!sortedVehicles) return;

    const keyword = searchString.replaceAll(" ", "").toLowerCase();

    const found = searchString
      ? sortedVehicles.filter(({ vehicle, customer }) =>
          `${vehicle}${customer}`
            .replaceAll(" ", "")
            .toLowerCase()
            .includes(keyword)
        )
      : [...sortedVehicles];

    setShownVehicles(found);
  }, [searchString, sortedVehicles]);

  const handleChangeSortingConfig = ({ field }) => {
    const { fields, direction } = sortingConfig;
    const invertedDirection = direction === "desc" ? "asc" : "desc";
    const newDirection = fields[0] !== field ? "desc" : invertedDirection;

    const newFields = TABLE_SORT_ORDER.reduce(
      (acc, el) => {
        if (el !== field) acc.push(el);
        return acc;
      },
      [field]
    );
    setSortingConfig({
      fields: newFields,
      direction: newDirection,
    });
  };

  if (!shownVehicles) {
    return (
      <div className={styles.table}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.table}>
      {shownVehicles.length ? (
        <>
          <div className={styles.header}>
            {Object.entries(columns).map(([field, data]) => {
              if (field === "shop" && !isAllLocations) return null;

              return (
                <div
                  key={field}
                  className={cn(styles.cell, styles[field], {
                    [styles.hidden]: data.hidden,
                    [styles.collapsed]: !data.hidden && data.collapsed,
                    [styles.sortable]: data.isSortable,
                  })}
                  style={{ order: data.order }}
                  onClick={() => {
                    if (data.isSortable) handleChangeSortingConfig({ field });
                  }}
                >
                  <div data-max-width-fit-content>{data.title}</div>
                  {data.tooltip && (
                    <InfoTooltip
                      text={data.tooltip}
                      className={styles.tooltip}
                    />
                  )}
                  {data.isSortable && (
                    <SortArrowDownIcon
                      className={cn([
                        styles.icon,
                        {
                          [styles.visible]: sortingConfig.fields[0] === field,
                          [styles.asc]:
                            sortingConfig.fields[0] === field &&
                            sortingConfig.direction === "asc",
                        },
                      ])}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {shownVehicles.map((vehicle) => (
            <VehicleRow
              key={vehicle.vehicleId}
              {...vehicle}
              isAllLocations={isAllLocations}
            />
          ))}
        </>
      ) : (
        <div className={styles.noData}>
          <RequestResult
            wrapperClassName={stylesRequestResult.noSearchDataVehiclesWrapper}
            className={stylesRequestResult.noSearchData}
            image={<NoResultIcon width={208} height={208} />}
            title="No Results Found"
            message={
              "We couldn't find anything matching your search.\nPlease try again."
            }
          />
        </div>
      )}
    </div>
  );
}

function VehicleRow({
  vehicleId,
  vehicle,
  deviceId,
  check,
  vinData,
  customerId,
  customer,
  service,
  serviceData,
  dtcs,
  warnings,
  warningsData,
  others,
  othersData,
  psi,
  tirePressures,
  shopId,
  shopName,
  shopAddress,
  isAllLocations,
}) {
  const history = useHistory();
  const columns = useSelector(getVehiclesColumns);
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedShopId = getRoutePrefix();

  const handleArrowClick = () => setIsExpanded((prev) => !prev);

  const handleVehicleClick = () => {
    if (selectedShopId === ALL_LOCATIONS.id) {
      setShopIdHeader(shopId);
      history.push(`/tenant/customer/${customerId}/vehicle/${vehicleId}`);
    } else {
      history.push(`/${shopId}/customer/${customerId}/vehicle/${vehicleId}`);
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div className={cn(styles.row, { [styles.expanded]: isExpanded })}>
      <div className={styles.desktop}>
        <VehicleCell
          {...{ vehicle, customer, checkEngine: check, vinData }}
          onClick={handleVehicleClick}
        />
        <div
          className={cn(styles.cell, styles.service, {
            [styles.hidden]: columns.service.hidden,
            [styles.collapsed]:
              !columns.service.hidden && columns.service.collapsed,
          })}
          style={{ order: columns.service.order }}
        >
          {serviceData ? (
            <>
              {service?.toLocaleString(["en-US"])}
              <div className={styles.name}>{serviceData.name}</div>
            </>
          ) : (
            "-"
          )}
        </div>
        <div
          className={cn(styles.cell, styles.check, {
            [styles.hidden]: columns.check.hidden,
            [styles.collapsed]:
              !columns.check.hidden && columns.check.collapsed,
          })}
          style={{ order: columns.check.order }}
        >
          {check ? "On" : "Off"}
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.dtcs.hidden,
            [styles.collapsed]: !columns.dtcs.hidden && columns.dtcs.collapsed,
          })}
          style={{ order: columns.dtcs.order }}
        >
          {dtcs ? (
            <>
              {dtcs}{" "}
              <InfoTooltip>
                <VehicleDTC
                  vehicleId={vehicleId}
                  deviceId={deviceId}
                  shopId={shopId}
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
        <div
          className={cn(styles.cell, styles.warnings, {
            [styles.hidden]: columns.warnings.hidden,
            [styles.collapsed]:
              !columns.warnings.hidden && columns.warnings.collapsed,
          })}
          style={{ order: columns.warnings.order }}
        >
          {warnings ? (
            <>
              {warnings}{" "}
              <InfoTooltip>
                <VehicleWarnings
                  warnings={Object.entries(warningsData).map(([id, data]) => ({
                    id,
                    text: data.text,
                  }))}
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.others.hidden,
            [styles.collapsed]:
              !columns.others.hidden && columns.others.collapsed,
          })}
          style={{ order: columns.others.order }}
        >
          {others ? (
            <>
              {others}{" "}
              <InfoTooltip>
                <VehicleWarnings
                  warnings={Object.entries(othersData).map(([id, data]) => ({
                    id,
                    text: data.text,
                  }))}
                  title="Other Alerts"
                />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
        <div
          className={cn(styles.cell, styles.psi, {
            [styles.hidden]: columns.psi.hidden,
            [styles.collapsed]: !columns.psi.hidden && columns.psi.collapsed,
          })}
          style={{ order: columns.psi.order }}
        >
          {psi ? (
            <>
              {psi}
              {" PSI "}
              <InfoTooltip>
                <VehiclePSI tirePressures={tirePressures} />
              </InfoTooltip>
            </>
          ) : (
            "-"
          )}
        </div>
        {isAllLocations && (
          <div
            className={cn(styles.cell, styles.shop, {
              [styles.hidden]: columns.shop.hidden,
              [styles.collapsed]:
                !columns.shop.hidden && columns.shop.collapsed,
            })}
            style={{ order: columns.shop.order }}
          >
            <div className={styles.name}>{shopName}</div>
            <div className={styles.address}>{shopAddress}</div>
          </div>
        )}
        <div className={styles.button} onClick={handleArrowClick}>
          <div className={styles.text}> {isExpanded ? "Less" : "More"}</div>
          <div className={styles.arrow} />
        </div>
      </div>
      <div className={styles.mobile}>
        <div
          className={cn(styles.cell, styles.vehicle, {
            [styles.hidden]: columns.vehicle.hidden,
            [styles.collapsed]:
              !columns.vehicle.hidden && columns.vehicle.collapsed,
          })}
          style={{ order: columns.vehicle.order }}
        >
          <div className={styles.parameter}>Vehicle / Customer</div>
          <VehicleCell
            {...{ vehicle, customer, checkEngine: check, vinData }}
            onClick={handleVehicleClick}
          />
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.service.hidden,
            [styles.collapsed]:
              !columns.service.hidden && columns.service.collapsed,
          })}
          style={{ order: columns.service.order }}
        >
          <div className={styles.parameter}>Miles Since Last Service</div>
          <div className={styles.service}>
            {serviceData ? (
              <>
                {service?.toLocaleString(["en-US"])}
                <div className={styles.name}>{serviceData.name}</div>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.check.hidden,
            [styles.collapsed]:
              !columns.check.hidden && columns.check.collapsed,
          })}
          style={{ order: columns.check.order }}
        >
          <div className={styles.parameter}>Check Engine Light</div>
          <div className={styles.value}>{check ? "On" : "Off"}</div>
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.dtcs.hidden,
            [styles.collapsed]: !columns.dtcs.hidden && columns.dtcs.collapsed,
          })}
          style={{ order: columns.dtcs.order }}
        >
          <div className={styles.parameter}>DTC Codes</div>
          <div className={styles.value}>
            {dtcs ? (
              <>
                {dtcs}{" "}
                <InfoTooltip>
                  <VehicleDTC
                    vehicleId={vehicleId}
                    deviceId={deviceId}
                    shopId={shopId}
                  />
                </InfoTooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.warnings.hidden,
            [styles.collapsed]:
              !columns.warnings.hidden && columns.warnings.collapsed,
          })}
          style={{ order: columns.warnings.order }}
        >
          <div className={styles.parameter}>Warning Lights</div>
          <div className={styles.value}>
            {warnings ? (
              <>
                {warnings}{" "}
                <InfoTooltip>
                  <VehicleWarnings
                    warnings={Object.entries(warningsData).map(
                      ([id, data]) => ({
                        id,
                        text: data.text,
                      })
                    )}
                  />
                </InfoTooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.others.hidden,
            [styles.collapsed]:
              !columns.others.hidden && columns.others.collapsed,
          })}
          style={{ order: columns.others.order }}
        >
          <div className={styles.parameter}>Other Alerts</div>
          <div className={styles.value}>
            {others ? (
              <>
                {others}{" "}
                <InfoTooltip>
                  <VehicleWarnings
                    warnings={Object.entries(othersData).map(([id, data]) => ({
                      id,
                      text: data.text,
                    }))}
                    title="Other Alerts"
                  />
                </InfoTooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div
          className={cn(styles.cell, {
            [styles.hidden]: columns.psi.hidden,
            [styles.collapsed]: !columns.psi.hidden && columns.psi.collapsed,
          })}
          style={{ order: columns.psi.order }}
        >
          <div className={styles.parameter}>Lowest PSI</div>
          <div className={styles.value}>
            {psi ? (
              <>
                {psi}
                {" PSI "}
                <InfoTooltip>
                  <VehiclePSI tirePressures={tirePressures} />
                </InfoTooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        {isAllLocations && (
          <div
            className={cn(styles.cell, {
              [styles.hidden]: columns.shop.hidden,
              [styles.collapsed]:
                !columns.shop.hidden && columns.shop.collapsed,
            })}
            style={{ order: columns.shop.order }}
          >
            <div className={styles.parameter}>Shop Location</div>
            <div className={styles.shop}>
              <div className={styles.name}>{shopName}</div>
              <div className={styles.address}>{shopAddress}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function VehicleCell({ vehicle, customer, checkEngine, vinData, onClick }) {
  const columns = useSelector(getVehiclesColumns);
  const [avatarURL, setAvatarURL] = useState(DefaultCarAvatar);
  const checkIcon = checkEngine ? EngineYellowIcon : EngineGreenIcon;

  useEffect(() => {
    (async () => {
      if (!vinData) return;
      const { year, make, model } = vinData;
      if (!year || !make || !model) return;

      const url = `https://images.mechanicadvisor.com/Resources/Upload/Images/Specifications/${year}_${replaceSpaceToUnderscore(
        make
      )}_${replaceSpaceToUnderscore(model)}.png`;

      try {
        const res = await fetch(url);
        if (res.ok) setAvatarURL(url);
      } catch {}
    })();
  }, []);

  return (
    <div
      className={cn(styles.cell, styles.vehicle, {
        [styles.hidden]: columns.vehicle.hidden,
        [styles.collapsed]:
          !columns.vehicle.hidden && columns.vehicle.collapsed,
      })}
      style={{ order: columns.vehicle.order }}
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: `url(${avatarURL})` }}
        className={styles.icon}
      >
        <EngineIcon icon={checkIcon} className={styles.check} />
      </div>
      <div className={styles.name}>
        {vehicle}
        <div className={styles.customer}>{customer}</div>
      </div>
    </div>
  );
}
