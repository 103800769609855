import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import PropTypes from "prop-types";

import { joinShopAddress, getFromLocalStorage } from "../../../utils";
import { HeaderShopsList } from "./components";
import { ArrowDownIcon } from "../../../assets/icons";

import { getShops, getSelectedShop } from "../shops.selectors";
import styles from "./HeaderShopToggle.module.scss";
import CompanyAvatar from "./components/CompanyAvatar/CompanyAvatar";
import { useScreenSize } from "../../../utility/hooks";
import { ALL_LOCATIONS } from "../../../constants";
import {
  getTenants,
  getHasMore,
  getTenantsShown,
  isTenantsLoading,
} from "../../tenants/tenants.selectors";
import HeaderTenantsList from "../../tenants/HeaderTenantsList/HeaderTenantsList";
import { getAdminStatus } from "../../../App/ui.slice";

const HeaderShopToggle = ({
  isBackgroundHover,
  onShopClick,
  className,
  onTenantClick,
  isHidden,
}) => {
  const toggleRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [toggleDisplayPosition, setToggleDisplayPosition] = useState("right");
  const shops = useSelector(getShops);
  const { isMobile } = useScreenSize();
  let selectedShop = useSelector(getSelectedShop);
  const isAdmin = useSelector(getAdminStatus);
  const isMultipleShops = shops.length > 1;
  const isTenantsListShown = useSelector(getTenantsShown);
  let selectedTenant = getFromLocalStorage("selectedTenant");
  const tenants = useSelector(getTenants);
  const tenantsHasMore = useSelector(getHasMore);
  const isLoadingTenants = useSelector(isTenantsLoading);
  const isAllLocations = selectedShop.id === ALL_LOCATIONS.id;

  function handleShopClick(shop) {
    setDropdownOpen(false);
    onShopClick(shop);
  }

  const handleAllLocationsClick = () => {
    handleShopClick(ALL_LOCATIONS);
  };

  function handleTenantClick(tenant) {
    onTenantClick(tenant);
  }

  const shopAddress = isAllLocations
    ? selectedShop.streetAddress
    : joinShopAddress(selectedShop, false);

  const getDisplayPositionShopToggle = () => {
    try {
      const rect = toggleRef.current.getBoundingClientRect();
      if (typeof rect === "undefined") {
        throw new Error("The ref to toggler is broken");
      }
      const value = rect.left - window.pageXOffset;
      return value > 250 ? "right" : "left";
    } catch (e) {
      console.error(e);
      return "right";
    }
  };

  useEffect(() => {
    setToggleDisplayPosition(getDisplayPositionShopToggle());
  }, [isDropdownOpen]);

  return (
    <div
      ref={(elm) => {
        toggleRef.current = elm;
      }}
      className={cn(
        styles.HeaderShopToggle,
        "relative flex items-center",
        className,
        {
          [styles.isActive]: isBackgroundHover && isDropdownOpen,
          [styles.isBackgroundHover]:
            isBackgroundHover && (isMultipleShops || isAdmin),
          [styles.hidden]: isHidden,
        }
      )}
    >
      <div
        className={cn(
          styles.HeaderShopToggle__button,
          "flex justify-center items-center shops-dropdown-toggle",
          {
            pointer: isMultipleShops || isAdmin,
          }
        )}
        onClick={() => {
          if (isMultipleShops || isAdmin) {
            setDropdownOpen(!isDropdownOpen);
          }
        }}
      >
        <div
          className={cn(
            styles.HeaderShopToggle__shopLogo,
            "flex items-center justify-center border-radius-circle"
          )}
        >
          {/* If shop has a logo configured, show shop's logo instead*/}
          <CompanyAvatar
            className={styles.companyLogo}
            isLogoActive={isDropdownOpen}
            isAllLocation={selectedShop?.id === ALL_LOCATIONS.id}
            avatar={selectedShop?.logoPath}
            width={isMobile ? 42 : 44}
            height={isMobile ? 42 : 44}
            alt={selectedShop ? selectedShop.name : "default shop"}
            defaultLogoSizePercent={0.6}
          />
        </div>
        <div className={cn(styles.HeaderShopToggle__info, "flex-1 pl-16")}>
          <span
            className={cn(
              styles.HeaderShopToggle__shopName,
              "font-bold font-md",
              {
                [styles.isActive]: isDropdownOpen,
                "font-lg": !shopAddress,
              }
            )}
          >
            {selectedShop ? selectedShop.name : "default shop"}
          </span>
          <span
            className={cn(
              styles.HeaderShopToggle__shopAddress,
              "font-medium font-xs"
            )}
          >
            {selectedShop ? shopAddress : "default shop"}
          </span>
        </div>
        {(isMultipleShops || isAdmin) && (
          <ArrowDownIcon
            className={cn(styles.HeaderShopToggle__arrow, {
              [styles.isRotated]: isDropdownOpen,
            })}
          />
        )}
      </div>

      {isDropdownOpen &&
        (isAdmin && isTenantsListShown ? (
          <HeaderTenantsList
            toggleDisplayPosition={toggleDisplayPosition}
            tenants={tenants}
            hasMore={tenantsHasMore}
            selectedTenantId={selectedTenant && selectedTenant.id}
            onTenantClick={handleTenantClick}
            setDropdownOpen={setDropdownOpen}
            outsideClickIgnoreClass="shops-dropdown-toggle"
            isLoading={isLoadingTenants}
          />
        ) : (
          <HeaderShopsList
            toggleDisplayPosition={toggleDisplayPosition}
            shops={shops}
            selectedShopId={selectedShop && selectedShop.id}
            onShopClick={handleShopClick}
            setDropdownOpen={setDropdownOpen}
            outsideClickIgnoreClass="shops-dropdown-toggle"
            onAllLocationsClick={handleAllLocationsClick}
            showAllLocations={isMultipleShops}
            isAllLocationsSelected={isAllLocations}
          />
        ))}
    </div>
  );
};

HeaderShopToggle.propTypes = {
  isBackgroundHover: PropTypes.bool,
  onShopClick: PropTypes.func,
  isSettings: PropTypes.bool,
};
export default HeaderShopToggle;
