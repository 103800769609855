import React from "react";
import cn from "classnames";
import styles from "./ShopItem.module.scss";

export const ShopItem = ({
  name,
  streetAddress,
  isSelected,
  onShopClick,
  isDisabled = false,
}) => {
  return (
    <li
      className={cn(styles.item, {
        [styles.isSelected]: isSelected,
        [styles.isDisabled]: isDisabled,
      })}
      onClick={() => !isDisabled && onShopClick()}
      data-tip={
        isDisabled ? "There are no shop locations in this tenant" : null
      }
      data-for="tooltipTenant"
    >
      <span
        className={cn(
          styles.shopName,
          "font-bold font-md text-color-secondary"
        )}
      >
        {name}
      </span>
      {streetAddress && (
        <span
          className={cn(
            styles.shopAddress,
            "font-medium font-xs text-color-quaternary"
          )}
        >
          {streetAddress}
        </span>
      )}
    </li>
  );
};
