import React, { useState, useEffect } from "react";
import cn from "classnames";
import * as styles from "./Layout.module.scss";
import Header from "../scenes/header/Header";
import Menu from "../components/menu/Menu";
import DeviceModal from "../scenes/header/DeviceModal";

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const handleMenuToggle = () => setIsMenuOpen((x) => !x);
  const handleMenuToggleMobile = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setIsMenuVisible((x) => !x);
    setIsMenuOpen(true);
  };
  const handleMenuItemClick = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setIsMenuVisible(false);
  };

  useEffect(() => {
    // adjust elements max-width to fit text content (removes extra space on the right after text is wrapped)

    let timeout = null;
    function adjustMaxWidth() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const elements = document.querySelectorAll(
          "[data-max-width-fit-content]"
        );

        elements.forEach((el) => {
          el.style.maxWidth = "initial";
          const range = document.createRange();
          const text = el.firstChild;
          range.setStartBefore(text);
          range.setEndAfter(text);
          const clientRect = range.getBoundingClientRect();
          el.style.maxWidth = clientRect.width
            ? `${clientRect.width}px`
            : `initial`;
        });
      }, 250);
    }

    const resizeObserver = new ResizeObserver(() => adjustMaxWidth());
    resizeObserver.observe(document.querySelector(`.${styles.layout}`));

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className={cn(styles.layout, { [styles.menuOpen]: isMenuOpen })}>
      <Header
        isMenuVisible={isMenuVisible}
        onMenuToggle={handleMenuToggleMobile}
      />
      <Menu
        isOpen={isMenuOpen}
        isVisible={isMenuVisible}
        onToggle={handleMenuToggle}
        onMenuToggle={handleMenuToggleMobile}
        onItemClick={handleMenuItemClick}
      />
      <div>{children}</div>
      <DeviceModal />
    </div>
  );
};

export default Layout;
