import React, { useMemo } from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Menu.module.scss";
import {
  MenuButton,
  SteermaticsLogoMenu,
  SteermaticsLogoMenuMobile,
  MenuDashboardIcon,
  MenuVehicleIcon,
  MenuDeviceIcon,
  MenuCustomersIcon,
  CloseMenuMobileIcon,
  SteerLogoIcon,
} from "../../assets/icons";
import UserDropdown from "./userDropdown/UserDropdown";
import { getRoutePrefix } from "../../services/env.service";

const ITEMS = [
  { text: "Dashboard", icon: <MenuDashboardIcon />, path: "dashboard" },
  { text: "Vehicles", icon: <MenuVehicleIcon />, path: "vehicles" },
  {
    text: "Device Inventory",
    icon: <MenuDeviceIcon />,
    path: "device-inventory",
  },
  { text: "Customers", icon: <MenuCustomersIcon />, path: "customers" },
];

export default function Menu({
  isOpen,
  onToggle,
  isVisible,
  onMenuToggle,
  onItemClick,
}) {
  const history = useHistory();
  const location = useLocation();
  const activeTab = useMemo(
    () => location.pathname.split("/")[2],
    [location.pathname]
  );

  const handleItemClick = (path) => {
    onItemClick();
    const shopId = getRoutePrefix();
    history.push(`/${shopId}/${path}`);
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.closed]: !isOpen,
        [styles.visible]: isVisible,
        ["body-overflow-hidden"]: isVisible,
      })}
    >
      <MenuButton className={styles.button} onClick={onToggle} />
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <SteermaticsLogoMenu
            className={styles.logo}
            onClick={() => window.location.replace("/")}
          />
          <SteermaticsLogoMenuMobile
            className={styles.logoMobile}
            onClick={() => window.location.replace("/")}
          />
        </div>
        <div className={styles.logoContainerMobile}>
          <CloseMenuMobileIcon
            className={styles.closeButton}
            onClick={onMenuToggle}
          />
          <SteerLogoIcon
            className={styles.logo}
            onClick={() => window.location.replace("/")}
          />
        </div>
        <div className={styles.itemsContainer}>
          {ITEMS.map(({ text, icon, path }, i) => (
            <div
              key={i}
              className={cn(styles.item, {
                [styles.active]: path.includes(activeTab),
              })}
              onClick={() => handleItemClick(path)}
            >
              <div className={styles.icon}>{icon}</div>
              <div className={styles.text}>{text}</div>
            </div>
          ))}
        </div>
        <div className={styles.actionsContainer}>
          <UserDropdown isMenuOpen={isOpen} />
        </div>
      </div>
    </div>
  );
}
