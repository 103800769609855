import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import classes from "./Alerts.module.scss";
import dropdownClasses from "../../../../styles/dropdown.module.scss";
import cn from "classnames";

import { ReactComponent as ChatIcon } from "../../../../assets/icons/chat.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as CarService } from "../../../../assets/icons/car-service.svg";

import { getMeasurementSystem } from "../../../customers/customers.selectors";
import { deleteAlert } from "../../vehicles.slice";

import Switch from "../../../../components/ui/switch/Switch";
import RangeInput from "../../../../components/ui/range-input/RangeInput";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { updateAlert } from "../../../../services/api.service";

import AlertItemModal from "./AlertItemModal";
import DeleteModal from "./DeleteModal";

const SHOP = "shop";
const ALERT = "alert";

export default function AlertItem({
  title,
  description,
  withSlider = true,
  measureLabel,
  minValue,
  maxValue,
  alert,
  refetchAlerts,
  alertConfig,
  showHeader = true,
}) {
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  const { _id: alertId, deviceId } = alert;

  const [isCheckedShop, setCheckedShop] = useState(alert.subscribers.shop);
  const [isCheckedAlert, setCheckedAlert] = useState(
    alert.subscribers.customer
  );
  const [isAlertDropdownOpened, setAlertOpenDropdown] = useState(false);

  const [sliderValues, setSliderValues] = useState(
    withSlider
      ? isMeasurementSystemImperial
        ? alert.triggerRange.map((range) => ({
            min: alertConfig.convertMetricToImperial(range[0]),
            max: alertConfig.convertMetricToImperial(range[1]),
          }))
        : alert.triggerRange.map((range) => ({
            min: range[0],
            max: range[1],
          }))
      : null
  );

  const [rangeKeys, setRangeKeys] = useState(
    alert.triggerRange.map((_, i) => i)
  );

  const sliderRefValues = useRef(sliderValues ? [...sliderValues] : null);

  function onSliderFinalChange(i) {
    if (
      sliderValues[i].min !== sliderRefValues.current[i].min ||
      sliderValues[i].max !== sliderRefValues.current[i].max
    ) {
      handleAlertChange({ i });
    }
  }

  function onInputFinalChange(i) {
    if (
      !alert.triggerRange[i] ||
      alert.triggerRange[i][0].toString() !== sliderValues[i].min.toString() ||
      alert.triggerRange[i][1].toString() !== sliderValues[i].max.toString()
    ) {
      handleAlertChange({ i });
    }
  }

  async function handleAlertChange({ i, toggleType }) {
    const currentState = {
      shop: isCheckedShop,
      customer: isCheckedAlert,
    };
    const subscribers = {};
    if (toggleType === SHOP) {
      currentState.shop = !currentState.shop;
      subscribers.shop = currentState.shop;
      setCheckedShop(!isCheckedShop);
    }
    if (toggleType === ALERT) {
      currentState.customer = !currentState.customer;
      subscribers.customer = currentState.customer;
      setCheckedAlert(!isCheckedAlert);
    }

    const alertUpdate = {
      _id: alertId,
      deviceId,
      subscribers,
    };

    if (i !== undefined) {
      const triggerRange = [...sliderValues];

      triggerRange[i] = {
        min: sliderRefValues.current[i].min,
        max: sliderRefValues.current[i].max,
      };

      alertUpdate.triggerRange = isMeasurementSystemImperial
        ? triggerRange.map((range) => [
            alertConfig.convertImperialToMetric(range.min).toString(),
            alertConfig.convertImperialToMetric(range.max).toString(),
          ])
        : triggerRange.map((range) => [
            range.min.toString(),
            range.max.toString(),
          ]);
    }

    updateAlert(alertUpdate);
  }

  async function addRange() {
    const triggerRange = [...sliderValues];
    triggerRange.push({ min: minValue, max: maxValue });

    const alertUpdate = {
      _id: alertId,
      deviceId,
      triggerRange: isMeasurementSystemImperial
        ? triggerRange.map((range) => [
            alertConfig.convertImperialToMetric(range.min).toString(),
            alertConfig.convertImperialToMetric(range.max).toString(),
          ])
        : triggerRange.map((range) => [
            range.min.toString(),
            range.max.toString(),
          ]),
    };

    updateAlert(alertUpdate);

    setSliderValues((prevState) => {
      return [...prevState, { min: minValue, max: maxValue }];
    });

    sliderRefValues.current.push({ min: minValue, max: maxValue });
    setRangeKeys((prev) => [...prev, prev[prev.length - 1] + 1]);
  }

  async function deleteRange(i) {
    const triggerRange = [...sliderValues];
    triggerRange.splice(i, 1);

    const alertUpdate = {
      _id: alertId,
      deviceId,
      triggerRange: isMeasurementSystemImperial
        ? triggerRange.map((range) => [
            alertConfig.convertImperialToMetric(range.min).toString(),
            alertConfig.convertImperialToMetric(range.max).toString(),
          ])
        : triggerRange.map((range) => [
            range.min.toString(),
            range.max.toString(),
          ]),
    };

    updateAlert(alertUpdate);
    setSliderValues(triggerRange);
    sliderRefValues.current.splice(i, 1);
    setRangeKeys((prev) => {
      const newstate = [...prev];
      newstate.splice(i, 1);
      return newstate;
    });
  }

  return (
    <div className={classes.AlertItem}>
      {showHeader && (
        <div className={classes.HeaderContainer}>
          <div className={classes.AlertItem__TitleWrapper}>
            <h6 className={classes.AlertItem__Title}>{title}</h6>
            {description && (
              <div className={classes.AlertItem__Description}>
                {description}
              </div>
            )}
          </div>
          <div
            className={cn([
              classes.AlertItem__ControlPanel,
              "d-flex align-items-center",
            ])}
          >
            <div className={classes.NotificationElement}>
              <CarService className={cn("mr-1", classes.DefaultIconColor)} />
              <Switch
                isChecked={isCheckedShop}
                onChange={() => handleAlertChange({ toggleType: SHOP })}
                toggleClass="ToggleGreen"
                dataTip={
                  isCheckedShop ? "Disable shop alert" : "Alert the shop"
                }
                dataFor={alertId}
              />
            </div>
            <div className={classes.NotificationElement}>
              <ChatIcon className={cn("mr-1", classes.DefaultIconColor)} />
              <Switch
                isChecked={isCheckedAlert}
                onChange={() => handleAlertChange({ toggleType: ALERT })}
                toggleClass="ToggleGreen"
                dataTip={
                  isCheckedAlert
                    ? "Disable customer alert"
                    : "Alert the customer via text message"
                }
                dataFor={alertId}
              />
            </div>
            <AlertDropdown
              isOpen={isAlertDropdownOpened}
              toggle={() => setAlertOpenDropdown(!isAlertDropdownOpened)}
              templates={alert.textTemplates}
              alertId={alertId}
              title={title}
              deviceId={deviceId}
              withSlider={withSlider}
            />
          </div>
          <ReactTooltip className={classes.Tooltip} id={alertId} />
        </div>
      )}
      {withSlider &&
        sliderValues.map((range, i) => (
          <div key={rangeKeys[i]} className={classes.rangeInput}>
            <RangeInput
              measureLabel={measureLabel}
              label={`Value range (${
                alertConfig.measurementUnits[
                  isMeasurementSystemImperial ? 1 : 0
                ]
              })`}
              minValue={minValue}
              maxValue={maxValue}
              step={1}
              value={range}
              withInputs
              onChange={({ min, max }) => {
                setSliderValues((prevState) => {
                  const newState = [...prevState];
                  newState[i] = { min, max };

                  return newState;
                });
                sliderRefValues.current[i] = { min, max };
              }}
              onSliderFinalChange={() => onSliderFinalChange(i)}
              onInputFinalChange={() => onInputFinalChange(i)}
              addRange={() => addRange()}
              deleteRange={() => deleteRange(i)}
              withAddRange={i === 0}
            />
          </div>
        ))}
    </div>
  );
}

function AlertDropdown({
  isOpen,
  toggle,
  alertId,
  title,
  deviceId,
  templates,
  withSlider,
}) {
  const [isModalOpened, setModalOpened] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [type, setType] = useState(SHOP);
  const [text, setText] = useState({ ...templates[SHOP] });
  const [textTemplates, setTextTemplates] = useState({
    ...templates,
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteAlert = () => {
    dispatch(deleteAlert({ alertId, deviceId }));
  };

  async function handleAlertTextChange({ type, text }) {
    const alertUpdate = {
      _id: alertId,
      deviceId,
      textTemplates: {
        [type === SHOP ? SHOP : "customer"]: text,
      },
    };
    updateAlert(alertUpdate);

    setTextTemplates({ ...textTemplates, ...alertUpdate.textTemplates });
  }

  return (
    <div
      className={cn(classes.DropdownWrapper, {
        [classes.DropdownWrapperMobile]: !withSlider,
      })}
    >
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle tag="div" className={dropdownClasses.Dropdown__Toggle}>
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
        </DropdownToggle>
        <DropdownMenu className={dropdownClasses.Dropdown__Menu}>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={() => {
              setEditMode(false);
              setModalOpened(!isModalOpened);
              setType(SHOP);
              setText(textTemplates[SHOP]);
            }}
          >
            <ViewIcon className={dropdownClasses.Dropdown__Icon} />
            <div>View Shop Alert Template</div>
          </DropdownItem>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={() => {
              setEditMode(true);
              setModalOpened(!isModalOpened);
              setType(SHOP);
              setText(textTemplates[SHOP]);
            }}
          >
            <EditIcon className={dropdownClasses.Dropdown__Icon} />
            <div>Edit Shop Alert Template</div>
          </DropdownItem>
          <div className={dropdownClasses.line}></div>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={() => {
              setEditMode(false);
              setModalOpened(!isModalOpened);
              setType(ALERT);
              setText(textTemplates.customer);
            }}
          >
            <ViewIcon className={dropdownClasses.Dropdown__Icon} />
            <div>View Customer Alert Template</div>
          </DropdownItem>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={() => {
              setEditMode(true);
              setModalOpened(!isModalOpened);
              setType(ALERT);
              setText(textTemplates.customer);
            }}
          >
            <EditIcon className={dropdownClasses.Dropdown__Icon} />
            <div>Edit Customer Alert Template</div>
          </DropdownItem>
          <div className={dropdownClasses.line}></div>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={() => setDeleteModalOpen(true)}
          >
            <DeleteIcon className={dropdownClasses.Dropdown__Icon} />
            <div>Delete Alert</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <AlertItemModal
        isEdit={isEditMode}
        isOpen={isModalOpened}
        toggle={() => setModalOpened(!isModalOpened)}
        type={type}
        text={text}
        updateAlertText={(text) => handleAlertTextChange({ type: type, text })}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClick={handleDeleteAlert}
        alertName={title}
      />
    </div>
  );
}
