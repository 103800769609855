import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";
import styles from "./Devices.module.scss";

import { getCustomers } from "../customers/customers.selectors";
import {
  getShopDevices,
  getSelectedShopId,
  getShopsById,
} from "../shops/shops.selectors";

import { ALL_LOCATIONS } from "../../constants";
import { joinShopAddress } from "../../utils";
import { useScreenSize } from "../../utility/hooks";

import Spinner from "../../components/spinner/Spinner";

import customerCellStyles from "../customers/customer-table/customer-cell/CustomerCell.module.scss";
import Typography from "../../components/typography/Typography";
import { selectCustomer } from "../customers/customers.slice";
import { showDeviceModal } from "../customer-details/vehicles.slice";

import {
  InstalledDevicesIcon,
  AvailableToInstallDevicesIcon,
  AvailableInventoryIcon,
  NoVehiclesIcon,
  SortArrowDownIcon,
  BusinessProfileIcon,
  AvatarIcon,
  MenuDeviceIcon,
  ArrowDownIcon,
} from "../../assets/icons/index";

const TABLE_SORT_ORDER = ["total", "installed", "engineOn", "engineOff"];

const sortData = (data, { fields, direction }) => {
  return [...data].sort((a, b) => {
    for (const field of fields) {
      const aValue = a[field] ?? "N/A";
      const bValue = b[field] ?? "N/A";

      if (aValue === "N/A" && bValue !== "N/A")
        return direction === "asc" ? -1 : 1;
      if (bValue === "N/A" && aValue !== "N/A")
        return direction === "asc" ? 1 : -1;

      if (aValue === bValue) continue;
      return direction === "asc"
        ? aValue > bValue
          ? 1
          : -1
        : aValue > bValue
        ? -1
        : 1;
    }
    return 0;
  });
};

export default function Devices() {
  const customers = useSelector(getCustomers);
  const shopDevices = useSelector(getShopDevices);
  const selectedShopId = useSelector(getSelectedShopId);
  const isAllLocations = selectedShopId === ALL_LOCATIONS.id;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Device Inventory</div>
      <div className={styles.section}>
        <Summary
          customers={customers}
          availableDevices={shopDevices?.length}
          isAllLocations={isAllLocations}
        />
      </div>
      <div className={styles.section}>
        <Table
          isAllLocations={isAllLocations}
          customers={customers}
          shopDevices={shopDevices}
        />
      </div>
    </div>
  );
}

function Summary({ customers, availableDevices, isAllLocations }) {
  const { isMiddlePC, isTablet } = useScreenSize();

  const counters = useMemo(() => {
    return customers.reduce(
      (acc, customer) => {
        acc.installed += customer.vehicles.length;
        return acc;
      },
      { installed: 0 }
    );
  }, [customers]);
  const totalAvailable = counters.installed + (availableDevices ?? 0);
  const totalAvailableTitle = `${
    isMiddlePC || isTablet ? "Total Avl." : "Total Available"
  } Devices`;

  const installedTitle = isAllLocations
    ? "Installed Devices"
    : `Installed in ${isMiddlePC || isTablet ? "" : "the "}Shop`;

  return (
    <div className={styles.summary}>
      <div className={styles.card}>
        <AvailableInventoryIcon />
        <div className={styles.info}>
          <div className={styles.title}>{totalAvailableTitle}</div>
          <div className={styles.number}>{totalAvailable}</div>
        </div>
      </div>

      <div className={styles.card}>
        <InstalledDevicesIcon />
        <div className={styles.info}>
          <div className={styles.title}>{installedTitle}</div>
          <div className={styles.number}>{counters.installed}</div>
        </div>
      </div>

      <div className={styles.card}>
        <AvailableToInstallDevicesIcon />
        <div className={styles.info}>
          <div className={styles.title}>Available to Install</div>
          <div className={styles.number}>{availableDevices ?? 0}</div>
        </div>
      </div>
    </div>
  );
}

function Table({ isAllLocations, customers, shopDevices }) {
  const shops = useSelector(getShopsById);

  const columns = {
    shop: {
      title: "Shop Location",
      hidden: !isAllLocations,
      collapsed: false,
    },
    customer: {
      title: "Customer",
      hidden: isAllLocations,
      collapsed: false,
    },
    total: {
      title: "Total Devices",
      hidden: !isAllLocations,
      collapsed: false,
    },
    installed: {
      title: "Installed",
      hidden: false,
      collapsed: false,
    },
    engineOn: {
      title: "Engine ON",
      hidden: false,
      collapsed: false,
    },
    engineOff: {
      title: "Engine OFF",
      hidden: false,
      collapsed: false,
    },
    installButton: {
      title: "",
      hidden: false,
      collapsed: false,
    },
  };

  const [devicesRowData, setDevicesRowData] = useState(null);

  const [sortingConfig, setSortingConfig] = useState({
    fields: TABLE_SORT_ORDER,
    direction: "desc",
  });

  const getShopDetails = (shopId) => {
    const shop = shops[shopId] || {};
    return {
      shopName: shop.name,
      shopAddress: joinShopAddress(shop, false),
      shop,
    };
  };

  const processedData = useMemo(() => {
    const calculateShopData = (shopId, shopCustomers) => {
      const { shopName, shopAddress, shop } = getShopDetails(shopId);

      // No customers for the shop, return a placeholder
      if (!shopCustomers.length) {
        return {
          shopId,
          shopName,
          shopAddress,
          shop,
          customers: [],
          vehicles: [],
          installed: 0,
          engineOn: 0,
          engineOff: 0,
          totalDevices: shopDevices?.length || 0,
        };
      }
      // Aggregate customer data for the shop
      const aggregatedData = shopCustomers.reduce(
        (acc, customer) => {
          const {
            vehiclesData,
            vehiclesWithConnectedDevices,
            vehiclesWithIgnitionOn,
            steerCustomerId,
            id: customerId,
            isBusinessAccount,
          } = customer;

          const vehicles = Object.entries(vehiclesData).map(
            ([vehicleId, { name: vehicle, deviceId }]) => ({
              vehicleId,
              vehicle,
              deviceId,
            })
          );

          acc.customers.push({
            customerId,
            customer: customer.name,
            isBusinessAccount,
            steerCustomerId,
          });

          acc.vehicles.push(...vehicles);
          acc.installed += vehiclesWithConnectedDevices.length;
          acc.engineOn += vehiclesWithIgnitionOn;
          acc.engineOff += vehicles.length - vehiclesWithIgnitionOn;

          return acc;
        },
        {
          shopId,
          shopName,
          shopAddress,
          shop,
          customers: [],
          vehicles: [],
          installed: 0,
          engineOn: 0,
          engineOff: 0,
          totalDevices: 0,
        }
      );

      aggregatedData.totalDevices =
        (shopDevices?.length || 0) + aggregatedData.installed;

      return aggregatedData;
    };

    if (isAllLocations) {
      return Object.values(shops).map((shop) => {
        const shopCustomers = customers.filter(
          (customer) => customer.shopId === shop.id
        );
        return calculateShopData(shop.id, shopCustomers);
      });
    }

    // For individual locations
    return customers.map((customer) => {
      const {
        vehiclesData,
        vehiclesWithConnectedDevices,
        vehiclesWithIgnitionOn,
        steerCustomerId,
        shopId,
        id: customerId,
        isBusinessAccount,
      } = customer;

      const { shopName, shopAddress, shop } = getShopDetails(shopId);

      const vehicles = Object.entries(vehiclesData).map(
        ([vehicleId, { name: vehicle, deviceId }]) => ({
          vehicleId,
          vehicle,
          deviceId,
        })
      );

      return {
        customerId,
        customer: customer.name,
        steerCustomerId,
        isBusinessAccount,
        shopId,
        shopName,
        shopAddress,
        shop,
        vehicles,
        installed: vehiclesWithConnectedDevices.length,
        engineOn: vehiclesWithIgnitionOn,
        engineOff: vehicles.length - vehiclesWithIgnitionOn,
        totalDevices:
          (shopDevices?.length || 0) + vehiclesWithConnectedDevices.length,
      };
    });
  }, [isAllLocations, customers, shops]);

  useEffect(() => {
    if (!processedData) return;

    const sortedData = sortData(processedData, sortingConfig);
    setDevicesRowData(sortedData);
  }, [processedData, sortingConfig]);

  if (!devicesRowData) {
    return (
      <div className={styles.table}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  const handleChangeSortingConfig = ({ field }) => {
    const { fields, direction } = sortingConfig;
    const invertedDirection = direction === "desc" ? "asc" : "desc";
    const newDirection = fields[0] !== field ? "desc" : invertedDirection;

    const newFields = TABLE_SORT_ORDER.reduce(
      (acc, el) => {
        if (el !== field) acc.push(el);
        return acc;
      },
      [field]
    );
    setSortingConfig({
      fields: newFields,
      direction: newDirection,
    });
  };

  return (
    <div className={styles.table}>
      {devicesRowData.length ? (
        <>
          <div className={styles.header}>
            {Object.entries(columns).map(([field, data]) => {
              return (
                <div
                  key={field}
                  className={cn(styles.cell, styles[field], {
                    [styles.hidden]: data.hidden,
                    [styles.collapsed]: data.collapsed,
                  })}
                  onClick={() => handleChangeSortingConfig({ field })}
                >
                  {data.title && (
                    <>
                      <span data-max-width-fit-content>{data.title}</span>
                      <SortArrowDownIcon
                        className={cn([
                          styles.icon,
                          {
                            [styles.visible]: sortingConfig.fields[0] === field,
                            [styles.asc]:
                              sortingConfig.fields[0] === field &&
                              sortingConfig.direction === "asc",
                          },
                        ])}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {devicesRowData.map((rowData, i) => (
            <DeviceTableRow
              key={i}
              {...rowData}
              columns={columns}
              isAllLocations={isAllLocations}
            />
          ))}
        </>
      ) : (
        <div className={styles.noData}>
          <NoVehiclesIcon className={styles.icon} />
          <div className={styles.text}>There is no data to display.</div>
        </div>
      )}
    </div>
  );
}

function DeviceTableRow({
  customerId,
  customer,
  isBusinessAccount,
  shopId,
  installed,
  engineOn,
  engineOff,
  columns,
  isAllLocations,
  shopName,
  shopAddress,
  totalDevices,
  shop,
  steerCustomerId,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();

  const handleArrowClick = () => setIsExpanded((prev) => !prev);

  const handleCustomerClick = () => {
    history.push(`/${shopId}/customer/${customerId}/`);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <>
      <div className={cn(styles.row, { [styles.expanded]: isExpanded })}>
        <div className={styles.desktop}>
          {isAllLocations && (
            <div className={cn(styles.cell, styles.shop)}>
              <div className={styles.name}>{shopName}</div>
              <div className={styles.address}>{shopAddress}</div>
            </div>
          )}
          {!isAllLocations && (
            <CustomerCell
              {...{
                isBusinessAccount,
                customerId,
                customer,
                shopId,
              }}
              onClick={handleCustomerClick}
            />
          )}
          {isAllLocations && (
            <div className={cn(styles.cell, styles.total)}>{totalDevices}</div>
          )}
          <div className={styles.cell}>{installed}</div>
          <div className={styles.cell}>{engineOn}</div>
          <div className={styles.cell}>{engineOff}</div>
          <div className={cn(styles.cell, styles.installButton)}>
            <InstallDeviceButton
              shop={shop}
              customerName={customer}
              isAllLocations={isAllLocations}
              steerCustomerId={steerCustomerId}
            />
          </div>
          <div
            className={cn(styles.arrowButton, {
              [styles.expanded]: isExpanded,
            })}
            onClick={handleArrowClick}
          >
            <ArrowDownIcon
              width="24"
              height="24"
              className={cn(styles.arrow)}
            />
          </div>
        </div>
        <div className={styles.mobile}>
          {isAllLocations && (
            <div
              className={cn(styles.cell, {
                [styles.hidden]: columns.total.hidden,
                [styles.collapsed]:
                  !columns.total.hidden && columns.total.collapsed,
              })}
            >
              <div className={styles.parameter}>Total Devices</div>
              <div className={styles.value}>{totalDevices}</div>
            </div>
          )}

          <div
            className={cn(styles.cell, {
              [styles.hidden]: columns.installed.hidden,
              [styles.collapsed]:
                !columns.installed.hidden && columns.installed.collapsed,
            })}
          >
            <div className={styles.parameter}>Installed</div>
            <div className={styles.value}>{installed}</div>
          </div>

          <div
            className={cn(styles.cell, {
              [styles.hidden]: columns.engineOn.hidden,
              [styles.collapsed]:
                !columns.engineOn.hidden && columns.engineOn.collapsed,
            })}
          >
            <div className={styles.parameter}>Engine ON</div>
            <div className={styles.value}>{engineOn}</div>
          </div>

          <div
            className={cn(styles.cell, {
              [styles.hidden]: columns.engineOff.hidden,
              [styles.collapsed]:
                !columns.engineOff.hidden && columns.engineOff.collapsed,
            })}
          >
            <div className={styles.parameter}>Engine OFF</div>
            <div className={styles.value}>{engineOff}</div>
          </div>

          <div className={cn(styles.cell, styles.installButton)}>
            <InstallDeviceButton
              shop={shop}
              steerCustomerId={steerCustomerId}
              customerName={customer}
              isAllLocations={isAllLocations}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const CustomerCell = ({ isBusinessAccount, customerId, customer, shopId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleOnClick() {
    dispatch(selectCustomer(null));
    history.push(`/${shopId}/customer/${customerId}`);
  }

  return (
    <div
      className={cn(customerCellStyles.customer, styles.cell, styles.customer)}
    >
      <div className={cn(customerCellStyles.avatar, "mr-15")}>
        {isBusinessAccount ? <BusinessProfileIcon /> : <AvatarIcon />}
      </div>
      <div
        onClick={() => handleOnClick()}
        className={customerCellStyles.nameWrapper}
      >
        <Typography
          tag="div"
          fontSize="md"
          fontWeight="bold"
          color="primary"
          ellipsis
          className={customerCellStyles.customerName}
        >
          {customer ? customer : "-"}
        </Typography>
      </div>
    </div>
  );
};

const InstallDeviceButton = ({
  isAllLocations,
  shop,
  steerCustomerId,
  customerName,
}) => {
  const { isTablet, isMiddlePC } = useScreenSize();
  const dispatch = useDispatch();
  const buttonTitle = isTablet || isMiddlePC ? "Install" : "Install Device";

  const openModalForRow = () => {
    const modalData = isAllLocations
      ? { shop: { ...shop } }
      : { customer: { id: steerCustomerId, name: customerName } };
    dispatch(showDeviceModal(modalData));
  };

  return (
    <div
      className={styles.buttonInstallDevice}
      onClick={() => openModalForRow()}
    >
      <MenuDeviceIcon className={styles.icon} />
      <div className={styles.title}>{buttonTitle}</div>
    </div>
  );
};
