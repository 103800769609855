import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { debounce } from "lodash";
import cn from "classnames";

import styles from "./Filters.module.scss";
import stylesRequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";

import { getVehiclesFilters, updateVehiclesFilters } from "../../App/ui.slice";

import {
  FiltersIcon,
  CloseIcon,
  ArrowBlueIcon,
  NoResultIcon,
} from "../../assets/icons";

import { Modal } from "reactstrap";

import Switch from "../../components/ui/switch/Switch";
import RangeInput from "../../components/ui/range-input/RangeInput";
import rangeInputStyles from "../../components/ui/range-input/styles.module.scss";
import SearchField from "../shops/HeaderShopToggle/components/SearchField/SearchField";
import RequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";

const FILTERS_CONFIG = {
  serviceMiles: {
    title: "Miles Since Last Service",
    measureUnit: "miles",
    component: FilterRange,
    minValue: 0,
    maxValue: 100000,
    getValue: ([min, max]) => `${min} - ${max}`,
  },
  serviceDays: {
    title: "Days Since Last Service",
    measureUnit: "days",
    component: FilterRange,
    minValue: 0,
    maxValue: 365,
    getValue: ([min, max]) => `${min} - ${max}`,
  },
  warnings: {
    title: "Vehicle Has a Warning Light ON",
    component: FilterBoolean,
    getValue: (data) => (data ? "Yes" : "No"),
  },
  others: {
    title: "Vehicle Has Other Alert ON",
    component: FilterBoolean,
    getValue: (data) => (data ? "Yes" : "No"),
  },
  checkEngine: {
    title: "Vehicle Has a Check Engine ON",
    component: FilterBoolean,
    getValue: (data) => (data ? "Yes" : "No"),
  },
  psi: {
    title: "Lowest PSI",
    measureUnit: "PSI",
    component: FilterRange,
    minValue: 0,
    maxValue: 100,
    getValue: ([min, max]) => `${min} - ${max}`,
  },
};

export default function Filters() {
  const dispatch = useDispatch();
  const filters = useSelector(getVehiclesFilters);
  const [searchString, setSearchString] = useState("");
  const [displayedFilters, setDisplayedFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState({});
  const refSummaryFilters = useRef(null);
  const [isSummaryMultiRow, setIsSummaryMultiRow] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);

  useEffect(() => {
    const search = searchString.toLocaleLowerCase().trim();

    const filtersToSet = Object.keys(filters).reduce((acc, id) => {
      const title = FILTERS_CONFIG[id].title.toLowerCase();
      if (title.includes(search)) acc[id] = filters[id];
      return acc;
    }, {});

    setDisplayedFilters(filtersToSet);
  }, [filters, searchString]);

  useEffect(() => {
    if (refSummaryFilters.current && !isSummaryExpanded) {
      const isMultiRow =
        refSummaryFilters.current.scrollWidth >
        refSummaryFilters.current.clientWidth;
      setIsSummaryMultiRow(isMultiRow);
    }
  }, [filters, update]);

  const summaryFilters = Object.entries({ ...filters, ...update }).reduce(
    (acc, [key, filter]) => {
      if (filter.isActive) {
        acc[key] = FILTERS_CONFIG[key].getValue(filter.data);
      }

      return acc;
    },
    {}
  );

  const counter = Object.values(filters).filter((x) => x.isActive).length;
  const counterUpdate = Object.values({ ...filters, ...update }).filter(
    (x) => x.isActive
  ).length;

  const handleToggle = () => {
    setIsOpen((x) => !x);
    setUpdate({});
    setSearchString("");
    setIsSummaryExpanded(false);
  };
  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchString(value);
    }, 300),
    []
  );
  const handleFilterChange = (value) => setUpdate((x) => ({ ...x, ...value }));
  const handleFilterClear = (id) => {
    const filterUpdate = {
      [id]: { ...filters[id], ...update[id], isActive: false },
    };
    setUpdate((x) => ({ ...x, ...filterUpdate }));
  };
  const handleFiltersClear = () => {
    const updateToSet = Object.entries({ ...filters, ...update }).reduce(
      (acc, [id, value]) => {
        if (value.isActive) acc[id] = { ...value, isActive: false };
        return acc;
      },
      {}
    );
    setUpdate((x) => ({ ...x, ...updateToSet }));
  };
  const handleFiltersUpdate = () => {
    if (Object.keys(update).length) {
      dispatch(updateVehiclesFilters(update));
      handleToggle();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.button} onClick={handleToggle}>
        <FiltersIcon />
        <div className={styles.title}>Filters</div>
        {counter ? <div className={styles.counter}>{counter}</div> : null}
      </div>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        className={styles.modal}
        contentClassName={styles.content}
      >
        <div className={styles.summary}>
          <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            {counterUpdate ? (
              <div className={styles.counter}>{counterUpdate}</div>
            ) : null}
            <CloseIcon className={styles.closeButton} onClick={handleToggle} />
          </div>
          <SearchField
            className={styles.search}
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          {counterUpdate ? (
            <div
              className={cn(styles.activeFilters, {
                [styles.expanded]: isSummaryExpanded,
              })}
              ref={refSummaryFilters}
            >
              <div className={styles.clearButton} onClick={handleFiltersClear}>
                <CloseIcon />
              </div>
              {Object.entries(summaryFilters).map(([key, value]) => (
                <div className={styles.filter}>
                  <div className={styles.title}>
                    {FILTERS_CONFIG[key].title}:
                  </div>
                  <div className={styles.value}>
                    {value}
                    <CloseIcon onClick={() => handleFilterClear(key)} />
                  </div>
                </div>
              ))}
              <div
                className={cn(styles.moreButton, {
                  [styles.hidden]: isSummaryExpanded || !isSummaryMultiRow,
                })}
              >
                <div
                  className={styles.clickArea}
                  onClick={() => setIsSummaryExpanded(true)}
                >
                  More
                  <ArrowBlueIcon className={styles.icon} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {Object.keys(displayedFilters).length ? (
          <>
            <div className={styles.container}>
              {Object.keys(displayedFilters).map((id) => {
                const Component = FILTERS_CONFIG[id].component;
                return (
                  <Component
                    id={id}
                    state={update[id] || displayedFilters[id]}
                    onChange={handleFilterChange}
                    config={FILTERS_CONFIG[id]}
                  />
                );
              })}
            </div>
            <div
              className={cn(styles.saveButton, {
                [styles.disabled]: !Object.keys(update).length,
              })}
              onClick={handleFiltersUpdate}
            >
              Show Results
            </div>
          </>
        ) : (
          <RequestResult
            wrapperClassName={stylesRequestResult.noSearchDataFiltersWrapper}
            className={stylesRequestResult.noSearchData}
            image={<NoResultIcon width={160} height={160} />}
            title="No Results Found"
            message={
              "We couldn't find anything matching your search.\nPlease try again."
            }
          />
        )}
      </Modal>
    </div>
  );
}

function FilterBoolean({ id, state, onChange }) {
  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (value) => {
    onChange({ [id]: { ...state, data: value } });
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <div className={styles.selectorBoolean}>
        <div
          className={cn(styles.item, { [styles.active]: state.data })}
          onClick={() => handleChange(true)}
        >
          Yes
        </div>
        <div
          className={cn(styles.item, { [styles.active]: !state.data })}
          onClick={() => handleChange(false)}
        >
          No
        </div>
      </div>
    </div>
  );
}

function FilterRange({ id, config, state, onChange }) {
  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (value) => {
    onChange({ [id]: { ...state, data: value } });
  };

  return (
    <div className={styles.card} key={id}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <RangeInput
        className={rangeInputStyles.filters}
        label={`Value range (${config.measureUnit})`}
        minValue={FILTERS_CONFIG[id].minValue}
        maxValue={FILTERS_CONFIG[id].maxValue}
        step={1}
        value={{ min: state.data[0], max: state.data[1] }}
        withInputs
        onChange={({ min, max }) => handleChange([min, max])}
        onSliderFinalChange={() => {}}
        onInputFinalChange={() => {}}
      />
    </div>
  );
}
