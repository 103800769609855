import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { fetchVehicleServicesHistory } from "../../vehicles.slice";
import {
  getSelectedVehicle,
  getServicesHistory,
} from "../../vehicles.selectors";
import Spinner from "../../../../components/spinner/Spinner";

import styles from "./ServicesHistory.module.scss";

import {
  EmptyServicesHistoryIcon,
  SortArrowDownIcon,
} from "../../../../assets/icons";

export default function ServicesHistory({ className }) {
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(getSelectedVehicle);
  const vehicleId = selectedVehicle?.id;
  const { data: services, isLoading } = useSelector(getServicesHistory);
  const [sortedServices, setSortedServices] = useState([]);
  const [sortingConfig, setSortingConfig] = useState({
    field: "daysAgo",
    direction: "desc",
  });

  useEffect(() => {
    if (!vehicleId) return;

    dispatch(fetchVehicleServicesHistory(vehicleId));
  }, [vehicleId]);

  useEffect(() => {
    if (!services) return;

    const sortedServices = [...services].sort((a, b) => {
      const { field, direction } = sortingConfig;
      return direction === "asc"
        ? (a[field] > b[field] && 1) || -1
        : (b[field] > a[field] && 1) || -1;
    });

    setSortedServices(sortedServices);
  }, [sortingConfig, services]);

  const handleChangeSortingConfig = ({ field: newField }) => {
    const { field, direction } = sortingConfig;
    const invertedDirection = direction === "desc" ? "asc" : "desc";
    const newDirection = field !== newField ? "desc" : invertedDirection;

    setSortingConfig({
      field: newField,
      direction: newDirection,
    });
  };

  if (!selectedVehicle) {
    return null;
  }

  if (isLoading || !services) {
    return (
      <div
        className={cn([styles.SpinnerWrapper, className, "position-relative"])}
      >
        <Spinner />
      </div>
    );
  }

  if (!services.length) {
    return (
      <div
        className={cn([
          className,
          styles.ServicesHistory,
          styles["ServicesHistory__no-data"],
        ])}
      >
        <div className="VehicleInfoPanel__headerLabel">Services History</div>
        <div className={styles.Container}>
          <EmptyServicesHistoryIcon className={styles.icon} />
          <div className="font-md font-bold">There is no data to display.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn([className, styles.ServicesHistory])}>
      <div className="VehicleInfoPanel__headerLabel">
        Services History{" "}
        <span className="VehicleInfoPanel__headerLabelCounter">
          ({services.length})
        </span>
      </div>

      <div className={styles.Header}>
        <div
          className={styles.Column}
          onClick={() => handleChangeSortingConfig({ field: "name" })}
        >
          Service
          <SortArrowDownIcon
            className={cn([
              styles.Icon,
              {
                [styles.Visible]: sortingConfig.field === "name",
                [styles.Asc]:
                  sortingConfig.field === "name" &&
                  sortingConfig.direction === "asc",
              },
            ])}
          />
        </div>
        <div
          className={styles.Column}
          onClick={() => handleChangeSortingConfig({ field: "daysAgo" })}
        >
          <span data-max-width-fit-content>Days Since Last Service</span>
          <SortArrowDownIcon
            className={cn([
              styles.Icon,
              {
                [styles.Visible]: sortingConfig.field === "daysAgo",
                [styles.Asc]:
                  sortingConfig.field === "daysAgo" &&
                  sortingConfig.direction === "asc",
              },
            ])}
          />
        </div>
        <div
          className={styles.Column}
          onClick={() => handleChangeSortingConfig({ field: "distanceAgo" })}
        >
          <span data-max-width-fit-content>Miles Since Last Service</span>
          <SortArrowDownIcon
            className={cn([
              styles.Icon,
              {
                [styles.Visible]: sortingConfig.field === "distanceAgo",
                [styles.Asc]:
                  sortingConfig.field === "distanceAgo" &&
                  sortingConfig.direction === "asc",
              },
            ])}
          />
        </div>
      </div>

      <div className={styles.RowsContainer}>
        {sortedServices.map((service, i) => (
          <ServiceRow data={service} key={i} />
        ))}
      </div>
    </div>
  );
}

function ServiceRow({ data }) {
  const { name, daysAgo, distanceAgo: milesAgo } = data;
  const distanceAgo = milesAgo.toLocaleString(["en-US"]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleRowClick = useCallback(() => setIsExpanded((prev) => !prev), []);

  return (
    <>
      <div className={styles.Row}>
        <div className={styles.Name}>{name}</div>
        <div className={styles.Value}>{daysAgo}</div>
        <div className={styles.Value}>{distanceAgo}</div>
      </div>
      {isExpanded ? (
        <div className={styles.MobileRowExpanded} onClick={handleRowClick}>
          <div className={styles.Name}>{name}</div>
          <div className={styles.ArrowIcon}></div>
          <div className={styles.Parameter}>
            <div className={styles.Name}>Days Since Last Service</div>
            <div className={styles.Value}>{daysAgo}</div>
          </div>
          <div className={styles.Parameter}>
            <div className={styles.Name}>Miles Since Last Service</div>
            <div className={styles.Value}>{distanceAgo}</div>
          </div>
        </div>
      ) : (
        <div
          className={cn([styles.Row, styles.MobileRow])}
          onClick={handleRowClick}
        >
          <div className={styles.Name}>{name}</div>
          <div className={styles.ArrowIcon}></div>
          <div className={styles.Value}>{daysAgo}</div>
          <div className={styles.Value}>{distanceAgo}</div>
        </div>
      )}
    </>
  );
}
