import React from "react";
import { useSelector } from "react-redux";
import Typography from "../../../../components/typography/Typography";
import { getShops } from "../../../shops/shops.selectors";
import { joinShopAddress } from "../../../../utils";
import { useScreenSize } from "../../../../utility/hooks";
import styles from "./ShopCell.module.scss";

const ShopCell = ({ value }) => {
  const { isMobile } = useScreenSize();
  const shops = useSelector(getShops);
  const shopData = shops.filter(
    ({ id }) => id.toLowerCase().indexOf(value?.toLowerCase()) !== -1
  )[0];

  const shopAddress = joinShopAddress(shopData, false);

  return (
    <div className={styles.shopWrapper}>
      <Typography
        tag="div"
        fontSize="md"
        fontWeight={isMobile ? "normal" : "bold"}
        color={isMobile ? "secondary" : "primary"}
        ellipsis
      >
        {value ? shopData.name : "-"}
      </Typography>
      {shopAddress && (
        <div className={styles.shopAddressWrapper}>
          <Typography
            tag="div"
            fontSize={isMobile ? "xs" : "sm"}
            color={isMobile ? "tertiary" : "secondary"}
            ellipsis
            className={styles.shopAddress}
          >
            {shopAddress}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ShopCell;
