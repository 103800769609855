import React from "react";
import cn from "classnames";

import classes from "./Switch.module.scss";

const Switch = ({
  cls,
  isChecked,
  onChange,
  disabled,
  leftLabel,
  rightLabel,
  activeColor = "#29c07b",
  toggleClass,
  dataTip,
  dataFor,
}) => (
  <button
    className={cn(cls, classes.Switch, {
      [classes["Switch__handle--disabled"]]: disabled,
    })}
    onClick={onChange}
    data-tip={dataTip}
    data-for={dataFor}
  >
    {leftLabel && (
      <label className={classes.Switch__left__label}>{leftLabel}</label>
    )}

    <span
      style={{ backgroundColor: isChecked && activeColor }}
      className={cn(classes.Switch__handle, {
        [classes["Switch__handle--isChecked"]]: isChecked,
      })}
    >
      <span className={cn(classes.Switch__toggle, classes[toggleClass])}></span>
    </span>

    {rightLabel && (
      <label className={classes.Switch__right__label}>{rightLabel}</label>
    )}
  </button>
);

export default Switch;
