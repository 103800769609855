import React, { useMemo } from "react";
import { TABLE_COLUMN_ID, ALL_LOCATIONS } from "../../constants";
import { useScreenSize } from "../../utility/hooks";
import CustomerCell from "../../scenes/customers/customer-table/customer-cell/CustomerCell";
import VehiclesCell from "../../scenes/customers/customer-table/vehicles-cell/VehiclesCell";
import ShopCell from "../../scenes/customers/customer-table/shop-cell/ShopCell";
import { getRoutePrefix } from "../../services/env.service";

export const useColumns = (isSelectableMobile) => {
  const { windowWidth, isMobile, isTablet, isMiddlePC, isPC, isLargePC } =
    useScreenSize();
  const selectedShopId = getRoutePrefix();
  const isAllLocation = selectedShopId === ALL_LOCATIONS.id;

  return useMemo(
    () => [
      {
        Header: "Customer",
        label: "Customer",
        id: TABLE_COLUMN_ID.CUSTOMER,
        accessor: "name",
        sticky: true,
        isVisible: true,
        flexGrow: 1,
        width: isLargePC
          ? 300
          : isPC
          ? 225
          : isMiddlePC
          ? 190
          : isTablet
          ? 180
          : 150,
        Cell: (row) => <CustomerCell row={row} />,
      },
      {
        Header: "Shop Location",
        label: "Shop Location",
        id: TABLE_COLUMN_ID.SHOP_LOCATION,
        accessor: "shopId",
        isVisible: isAllLocation && !isMobile,
        flexGrow: 1,
        width: isLargePC
          ? 320
          : isPC
          ? 214
          : isMiddlePC
          ? 158
          : isTablet
          ? 151
          : 147,
        // temporary disable sorting
        disableSortBy: true,
        Cell: (row) => <ShopCell value={row.cell.value} />,
      },
      {
        Header: "Vehicles",
        id: TABLE_COLUMN_ID.VEHICLES,
        accessor: "vehicles",
        label: "Vehicles",
        width: isLargePC ? 100 : isPC ? 85 : 73,
        isVisible: !isMobile,
        Cell: (row) => <VehiclesCell row={row} value={row.cell.value} />,
      },
      {
        Header: "Check Engine Light",
        id: TABLE_COLUMN_ID.VEHICLES_ENGINE_LIGHTS,
        accessor: "vehiclesWithCheckEngineLight",
        label: "Engine Lights",
        width: isLargePC ? 158 : isTablet ? 102 : 105,
        isVisible: !isMobile,
        Cell: (row) => <VehiclesCell row={row} value={row.cell.value} />,
      },
      {
        Header: "Warnings Light",
        id: TABLE_COLUMN_ID.VEHICLES_WARNINGS,
        accessor: "vehiclesWithWarnings",
        label: "Warnings Light",
        width: isLargePC ? 128 : isPC ? 85 : 75,
        isVisible: !isMobile,
        Cell: (row) => (
          <VehiclesCell row={row} value={row.cell.value} isWarningsCol />
        ),
      },
      {
        Header: "DTC Codes",
        id: TABLE_COLUMN_ID.VEHICLE_DTC_CODES,
        accessor: "vehiclesWithDTCCodes",
        label: "DTC Codes",
        width: isLargePC ? 110 : isPC ? 102 : 61,
        isVisible: !isMobile,
        Cell: (row) => <VehiclesCell row={row} value={row.cell.value} />,
      },
      {
        Header: "Other Alerts",
        id: TABLE_COLUMN_ID.VEHICLE_OTHER_ALERTS,
        accessor: "vehiclesWithOtherAlerts",
        label: "Other Alerts",
        width: isLargePC ? 134 : isPC ? 89 : isTablet ? 66 : 74,
        isVisible: !isMobile,
        Cell: (row) => (
          <VehiclesCell row={row} value={row.cell.value} isWarningsCol />
        ),
      },
    ],
    [isMobile, windowWidth, isSelectableMobile]
  );
};
