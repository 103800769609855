import axios from "axios";

import { CUSTOM_LABELS } from "../constants";

const apiEndpoint = process.env.REACT_APP_API_ROOT;

const client = axios.create({
  baseURL: `${apiEndpoint}/api/`,
});

let onTokenInvalid;
export function setOnTokenInvalid(cb) {
  onTokenInvalid = cb;
}

export function setClientAuthorizationHeader(token) {
  client.defaults.headers.Authorization = `bearer ${token}`;
}

export function removeClientAuthorizationHeader() {
  delete client.defaults.headers.Authorization;
}

export function setShopIdHeader(shopId) {
  client.defaults.headers["X-ShopId"] = shopId;
}

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      onTokenInvalid && onTokenInvalid();
    } else if (error.response.status === 400) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject("Something went wrong.");
    }
  }
);

async function authenticateUser({ username, password }) {
  const { status, data } = await client.post("/authenticate", {
    username,
    password,
  });

  const {
    accessToken,
    refreshToken,
    userName,
    firstName,
    lastName,
    phoneNumber,
  } = data;

  if (status === 200) {
    return {
      accessToken,
      refreshToken,
      userName,
      firstName,
      lastName,
      phoneNumber,
    };
  } else {
    return false;
  }
}

async function refreshUser(token) {
  const { status, data } = await client.post("/authenticate/refresh", {
    refreshToken: token,
  });

  const { accessToken, refreshToken } = data;

  if (status === 200) {
    return { accessToken, refreshToken };
  } else {
    return false;
  }
}

async function fetchFaults({ deviceId }) {
  const res = await client(`/devices/${deviceId}/faults`);
  return res.data;
}

async function clearDTC({ deviceId }) {
  client(`/devices/${deviceId}/clearDTC`);
}

async function fetchDeviceOverview(deviceId) {
  const res = await client(`/devices/${deviceId}/overview`);
  return res.data;
}

async function fetchCoreVehicleData({ deviceId }) {
  const res = await client(`/devices/${deviceId}/coreVehicleData`);
  return res.data;
}

async function fetchLatestMonitorData({ deviceId }) {
  const res = await client(`/devices/${deviceId}/monitors`);
  return res.data;
}

async function fetchCustomers(params) {
  const res = await client(`/customers`, { params });
  return res.data;
}

async function fetchTenantCustomers(shops) {
  const customerPromises = shops.map(async (shop) => {
    const res = await client(`/customers`, {
      headers: { "X-ShopId": shop.id },
    });
    return res.data;
  });

  const allCustomersData = await Promise.all(customerPromises);

  const allCustomers = allCustomersData.flatMap((data) =>
    data.customers.map((customer) => ({
      ...customer,
      provider: data.provider,
    }))
  );

  return allCustomers;
}

async function createCustomer(data) {
  const res = await client(`/customers`, {
    method: "POST",
    data,
  });
  return res.data;
}

async function editCustomer(data) {
  const res = await client(`/customers/${data.id}`, {
    method: "PUT",
    data,
  });
  return res.data;
}

async function deleteCustomer(data) {
  const { id } = data;
  await client(`/customers/${id}`, {
    method: "DELETE",
  });
}

async function fetchCustomerVehicles(customerId) {
  const res = await client(`/customers/${customerId}/vehicles`);
  return res.data;
}

async function createCustomerVehicle(data) {
  const res = await client(`/customers/${data.customerId}/vehicles`, {
    method: "POST",
    data,
  });
  if (res.status === 200) {
    return res.data;
  }
  throw new Error("Could not add new device");
}

async function editCustomerVehicle(data) {
  const { id, customerId } = data;
  const res = await client(`/customers/${customerId}/vehicles/${id}`, {
    method: "PUT",
    data,
  });
  return res.data;
}

async function deleteCustomerVehicle(data) {
  const { id, customerId, serialNumber, deviceId } = data;
  const res = await client(`/customers/${customerId}/vehicles/${id}`, {
    method: "DELETE",
    data: { serialNumber, deviceId },
  });
  return res.data;
}

async function fetchDiagnosticOptions(deviceId) {
  const res = await client(`/devices/${deviceId}/diagnosticIds`);
  const { data } = res;

  const withCustomLabels = data.map((item) => {
    if (CUSTOM_LABELS.hasOwnProperty(item.label)) {
      return {
        ...item,
        label: CUSTOM_LABELS[item.label],
      };
    }
    return item;
  });

  return withCustomLabels;
}

async function fetchDiagnosticValues(data) {
  const { deviceId, diagnosticId, from = "", to = "" } = data;

  const res = await client(
    `/devices/${deviceId}/vehicleStatuses?diagnosticId=${diagnosticId}&from=${from}&to=${to}`
  );

  return res.data;
}

async function fetchDiagnosticWarnings(data) {
  const { deviceId, diagnosticIds, from = "", to = "" } = data;

  const res = await client(
    `/devices/${deviceId}/warnings?diagnosticIds=${diagnosticIds}&from=${from}&to=${to}`
  );

  return res.data;
}

async function fetchVehicleAlerts({ vehicleId }) {
  const res = await client(`/devices/${vehicleId}/alerts`);

  return res.data;
}

async function createAlert(alert) {
  const res = await client(`/devices/${alert.deviceId}/alerts`, {
    method: "POST",
    data: alert,
  });
  return res.data;
}

async function updateAlert(alert) {
  client(`/devices/${alert.deviceId}/alerts/${alert._id}`, {
    method: "PUT",
    data: alert,
  });
}

async function deleteAlert({ alertId, deviceId }) {
  client(`/devices/${deviceId}/alerts/${alertId}`, {
    method: "DELETE",
  });
}

async function fetchShopDevices() {
  const res = await client(`/devices`);
  return res.data;
}

async function fetchAlertsPastActivityByVehicleId({ vehicleId, offset }) {
  const res = await client(
    `/customers/alertrecords/${vehicleId}/?offset=${offset}`
  );
  return res.data;
}

function resendCustomerConsent(id) {
  client(`/customers/consent`, {
    method: "POST",
    data: { id },
  });
}

async function fetchVehicleRecommendedServices({ customerId, vehicleId }) {
  const res = await client(
    `/customers/${customerId}/vehicles/${vehicleId}/recommendedServices`
  );

  return res.data;
}

async function changeVehicleRecommendedServices({
  customerId,
  vehicleId,
  services,
}) {
  const res = await client(
    `/customers/${customerId}/vehicles/${vehicleId}/recommendedServices`,
    {
      method: "POST",
      data: services,
    }
  );

  return res.data;
}

async function fetchAllShops() {
  const res = await client(`/shops`);
  return res.data;
}

async function fetchSteerCustomers(searchQuery) {
  const res = await client(
    `/customers/steer${searchQuery ? `?searchQuery=${searchQuery}` : ""}`
  );
  return res.data;
}

async function fetchCustomerSteerVehicles(steerCustomerId, searchQuery) {
  const res = await client(
    `/customers/${steerCustomerId}/vehicles/steer${
      searchQuery ? `?searchQuery=${searchQuery}` : ""
    }`
  );
  return res.data;
}

async function fetchAllUsers() {
  const res = await client(`/users`);
  return res.data;
}

async function createUser(data) {
  const res = await client(`/users`, {
    method: "POST",
    data,
  });
  return res.data;
}

async function editUser(data) {
  const { id } = data;
  const res = await client(`/users/${id}`, {
    method: "PUT",
    data,
  });
  return res.data;
}

async function deleteUser(data) {
  const { id } = data;
  await client(`/users/${id}`, {
    method: "DELETE",
  });
}

async function fetchTenants({ searchQuery, takeFrom }) {
  const res = await client("/tenants/", {
    params: {
      searchQuery,
      takeFrom,
    },
  });
  return res.data;
}

async function fetchTenantShops(tenantId) {
  const res = await client(`/tenants/${tenantId}/shops`);
  return res.data;
}

async function fetchVehicleServicesHistory({ customerId, vehicleId }) {
  const res = await client(
    `/customers/${customerId}/vehicles/${vehicleId}/servicesHistory`
  );

  return res.data;
}

export {
  authenticateUser,
  refreshUser,
  fetchFaults,
  clearDTC,
  fetchLatestMonitorData,
  fetchCustomers,
  createCustomer,
  editCustomer,
  deleteCustomer,
  fetchCustomerVehicles,
  fetchDeviceOverview,
  fetchCoreVehicleData,
  createCustomerVehicle,
  editCustomerVehicle,
  fetchDiagnosticOptions,
  deleteCustomerVehicle,
  fetchDiagnosticValues,
  fetchDiagnosticWarnings,
  fetchVehicleAlerts,
  createAlert,
  updateAlert,
  deleteAlert,
  fetchAlertsPastActivityByVehicleId,
  resendCustomerConsent,
  fetchVehicleRecommendedServices,
  changeVehicleRecommendedServices,
  fetchAllShops,
  fetchSteerCustomers,
  fetchCustomerSteerVehicles,
  fetchShopDevices,
  fetchAllUsers,
  createUser,
  editUser,
  deleteUser,
  fetchTenants,
  fetchTenantShops,
  fetchVehicleServicesHistory,
  fetchTenantCustomers,
};
